import { FaBookmark, FaCloud, FaCopy, FaSave, FaShare, FaStar } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Flag from "react-world-flags";
import ProductCard from "../../components/product";
import './index.css'
import Order from "../order";
import { IoAddCircle, IoAddSharp, IoLogOut, IoSettings, IoStatsChart } from "react-icons/io5";
import { BASE_URL, eraseCookie, getCookie, getUser, isLoggedIn } from "../../utils/utils";
import PremiumModal from "../../components/premium_modal";
import { MdAutoGraph } from "react-icons/md";
import ShareModal from "../../components/share_modal";
import axios from "axios";
import LoadingScreen from "../../components/loading_screen";
import { Product, User } from "../../typos";
import AccountModal from "../../components/account";
import CreateProductModal from "../../components/create_product_modal";
import PlansModal from "../../components/plans_modal";
import { Helmet } from "react-helmet";
import { get } from "http";
import { getLabels } from "../../utils/labels";
import DeleteModal from "../../components/delete_modal";
import LiveUrlModal from "../../components/live_url";

const Library = () => {

  const { libraryName } = useParams();
  const [searchParams, setSearchPrams] = useSearchParams()
  const navigate = useNavigate()
  const [showHidden, setShowHidden] = useState(false)
  const [isEditable, setIsEditable] = useState(true)
  const [products, setProducts] = useState([])
  const [selectedProductToBuy, setSelectedProductToBuy] = useState<Product>()
  const [selectedProductToRemove, setSelectedProductToRemove] = useState<Product>()
  const [loggedIn, setLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [visibleModal, setVisibleModal] = useState('')
  const [userData, setUserData] = useState<User>({
    bio: "",
    city: "",
    name: "",
    surname: "",
    country_code: "",
    created_on: "", // Assuming this is in ISO 8601 format
    email: "",
    url: "",
    color: "",
    image_path: "",
    library_name: "",
    own: false,
    paypal_email: "",
    premium: false,
    user_id: 0
  })

  useEffect(() => {
    handleAuth()
  },[libraryName])

  const handleAuth = async () => {
    const loggedIn_ = await isLoggedIn()
    setLoggedIn(loggedIn_)
    getUserPage(loggedIn_)
  }

  const getUserPage = async (loggedIn: boolean) => {
    if (loggedIn) {
      await axios.get(BASE_URL + "/users/lib/" + libraryName, {
        headers: {
          "Authorization": "Bearer " + getCookie("jwt-token")
        }
      })
      .then((res) => {
        setUserData(res.data.param)
        setIsEditable(res.data.param.own)
        getProducts(res.data.param.user_id)

        if (res.data.param.own && !res.data.param.premium) {
          setVisibleModal('plans')
        }
      })
      .catch((err) => {
        navigate("/")
      })
    } else {
      await axios.get(BASE_URL + "/users/lib/" + libraryName)
      .then((res) => {
        setUserData(res.data.param)
        setIsEditable(res.data.param.own)
        getProducts(res.data.param.user_id)
      })
      .catch((err) => {
        navigate("/")
      })
    }
  }

  const getProducts = async (userId: number) => {
    await axios.get(BASE_URL + "/products/user/" + userId)
    .then((res) => {
      setProducts(res.data.param)
      setIsLoading(false)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const getHiddenProducts = async (userId: number) => {
    await axios.get(BASE_URL + "/products/user/" + userId + "/hidden")
    .then((res) => {
      setProducts(res.data.param)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleRedirectToOwnPage = async () => {
    const user: any = await getUser()
    navigate("/" + user?.library_name)
  }
  
  return (
    <>
      <Helmet>
        <title>Uteka - {userData.library_name} ({userData.name} {userData.surname})</title>
      </Helmet>
      <div className="bg-[#030816] pagepadding overflow-hidden w-screen h-screen">
        {isLoading ? (
          <>
            <LoadingScreen/>
            <div className="top-0 left-0 w-screen bg-[white] bg-opacity-90 fixed h-screen"></div>
          </>
        ):(
          <>
            <Order 
              visible={visibleModal == 'checkout'} 
              onClose={() => setVisibleModal('')} 
              payee_paypal_email={userData.paypal_email} 
              product={selectedProductToBuy}
            />
            <LiveUrlModal
              onClose={() => {
                navigate("/" + userData.url.split("//")[1].split("/")[1])}
              }
              visible={searchParams.has("new") && searchParams.get("new") == "true"}
              url={userData.url}
            />
            <PremiumModal 
              onClose={() => setVisibleModal('')} 
              name={userData?.name + " " + userData?.surname} 
              visible={visibleModal == 'premium'}
            />
            <DeleteModal
              product={selectedProductToRemove}
              onClose={(articleRemoved) => {
                setVisibleModal('')
                if (articleRemoved) {
                  getHiddenProducts(userData.user_id)
                }
              }}
              visible={visibleModal == 'delete'}
            />
            <CreateProductModal 
              onClose={(articleAdded) => {
                setVisibleModal('')
                if (articleAdded) {
                  getProducts(userData.user_id)
                }
              }} 
              visible={visibleModal == 'create'}
            />
            {/*<PlansModal onClose={(refreshNeeded) => {
              setVisibleModal('')
              if (refreshNeeded) {
                navigate(0)
              }
            }} visible={visibleModal == 'plans'}/>*/}
            <AccountModal 
              onChange={(e) => setUserData(e)} 
              onClose={() => setVisibleModal('')} 
              user={userData} 
              visible={visibleModal == 'account'}
            />
            <ShareModal 
              onClose={() => setVisibleModal('')} 
              url={userData?.url} 
              visible={visibleModal == 'share'}
            />
            <div className="flex justify-between bz-30 flex w-screen h-screen relative w-full flex-block">
              <div className="block-none">
                <label className="w-64 h-64 flex justify-around items-center rounded-full">
                  <img style={{ borderColor: userData?.color }} className="border-4 w-full rounded-full h-full object-cover" src={userData?.image_path} alt="" />
                </label>
                <div className="mt-8 flex items-center gap-3">
                  <h1 className="font-semibold text-[white] text-4xl">{userData?.name} {userData?.surname}</h1>
                  {userData.premium ? (
                    <button onClick={() => setVisibleModal('premium')} >
                      <FaStar size={24} color="orange"/>
                    </button>
                  ): null}
                </div>
                <h2 style={{ textDecorationColor: userData?.color }} className="underline text-[#95a3b9] text-lg mt-1">@{userData?.library_name}</h2>
                <p style={{ maxWidth: 484 }} className="text-[#95a3b9] text-lg mt-2">{userData?.bio}</p>
                <div className="flex justify-between">
                  <a href={"https://www.google.com/maps/place/" + userData.city} target="__blank">
                    <button style={{ borderColor: userData?.color }} className="gap-3 items-center rounded-full pl-4 flex border-2 pr-4 pt-1 pb-1 mt-8">
                      <div className="flex items-center gap-1">
                        <HiOutlineLocationMarker size={18} color={userData?.color}/>
                        <p className="text-[white]">{userData?.city}</p>
                      </div>
                      <div>
                        <Flag className="rounded-sm w-5" code={userData?.country_code} />
                      </div>
                    </button>
                  </a>
                </div>
              </div>
              <div className="none-block">
                <div className="items-center flex">
                  <div className="md:w-24 md:h-24 w-16 h-16">
                    <img style={{ borderColor: userData?.color }} className="border-2 w-full rounded-full h-full object-cover" src={userData?.image_path} alt="" />
                  </div>
                  <div className="ml-4">
                    <div className="flex items-center gap-3">
                      <h1 className="font-semibold text-[white] md:text-2xl text-xl">{userData?.name} {userData?.surname}</h1>
                      {userData?.premium ? (
                        <button onClick={() => setVisibleModal('premium')} >
                          <FaStar size={18} color="orange"/>
                        </button>
                      ): null}
                    </div>
                    <h2 style={{ textDecorationColor: userData?.color }} className="underline text-[#95a3b9] md:text-lg text:md">@{userData?.library_name}</h2>
                  </div>
                </div>
                <div>
                  <p style={{ maxWidth: 314 }} className="text-[#95a3b9] md:text-md text-sm md:mt-6 mt-2">{userData?.bio}</p>
                </div>
                <div className="pb-6 flex justify-between">
                  <a href={"https://www.google.com/maps/place/" + userData.city} target="__blank">
                    <button style={{ borderColor: userData?.color }} className="gap-3 items-center rounded-full pl-3 flex border-2 pr-3 pt-1 pb-1 mt-4">
                      <div className="flex items-center gap-1">
                        <HiOutlineLocationMarker size={14} color={userData?.color}/>
                        <p className="text-sm text-[white]">{userData?.city}</p>
                      </div>
                      <div>
                        <Flag className="rounded-sm w-4" code={userData?.country_code} />
                      </div>
                    </button>
                  </a>
                </div>
              </div>
              <div className="justify-around w-3/4 flex-none p-4">
                <div style={{ height: 664 }} className="overflow-y-scroll flex flex-wrap p-24 pr-0 pt-0">
                  {products.map((product: Product, index) => (
                    <ProductCard
                      onClickToBuy={() => {
                        setSelectedProductToBuy(product)
                        setVisibleModal('checkout')
                      }}
                      onClickToRemove={() => {
                        setSelectedProductToRemove(product)
                        setVisibleModal('delete')
                      }}
                      color={userData.color}
                      onHide={() => getProducts(userData.user_id)}
                      onUnHide={() => getHiddenProducts(userData.user_id)}
                      product={product}
                      isEditable={isEditable}
                    />
                  ))}
                </div>
              </div>
              <div className="justify-around w-5/6 flex-wrap none-flex">
                <div className="h-[545px] pb-24 overflow-y-scroll">
                  {products.map((product: Product, index) => (
                    <ProductCard
                      onClickToBuy={() => {
                        setSelectedProductToBuy(product)
                        setVisibleModal('checkout')
                      }}
                      onClickToRemove={() => {
                        setSelectedProductToRemove(product)
                        setVisibleModal('delete')
                      }}
                      color={userData.color}
                      onHide={() => getProducts(userData.user_id)}
                      onUnHide={() => getHiddenProducts(userData.user_id)}
                      product={product}
                      isEditable={isEditable}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="z-30 w-screen bmenupadding right-0 left-0 flex-none justify-between items-center bottom-8 fixed">
              {loggedIn ? (
                isEditable ? (
                  <>
                    <div className="rounded-full gap-4 flex border-[#373D52] border text-[#373D52] pr-3 pt-2 pb-2 pl-3 bg-[#1e283a]">
                      <div className="flex gap-2">
                        <button 
                          onClick={() => setVisibleModal('share')} 
                          className="p-2 transition-all hover:bg-opacity-30 hover:text-[orange] hover:bg-[orange] 
                                     rounded-full text-[orange] border-[orange] border"
                        >
                          <FaShare size={18}/>
                        </button>
                        <button 
                          onClick={() => navigate("/" + userData?.library_name + "/analytics")} 
                          className="p-2 hover:bg-opacity-30 transition-all hover:text-[orange] hover:bg-[orange] 
                          rounded-full border border-[orange] text-[orange]"
                        >
                          <MdAutoGraph size={18}/>
                        </button>
                      </div>
                      <div className="flex gap-2">
                        <button 
                          onClick={() => setVisibleModal("create")} 
                          className="p-2 transition-all hover:text-[orange] hover:bg-opacity-30 
                                     border border-[orange] rounded-full bg-[orange] text-[white]"
                        >
                          <IoAddSharp size={18}/>
                        </button>
                      </div>
                      <div className="flex gap-2">
                        <button 
                          onClick={() => setVisibleModal("account")} 
                          className="p-2 transition-all hover:text-[orange] hover:text-[orange] hover:bg-[orange] 
                                     hover:bg-opacity-30 rounded-full border border-[orange] text-[orange]"
                        >
                          <IoSettings size={18}/>
                        </button>
                        <button 
                          onClick={() => {
                            eraseCookie("jwt-token")
                            navigate(0)
                          }} 
                          className="p-2 transition-all hover:bg-opacity-30 hover:text-[orange] hover:bg-[orange] 
                                     rounded-full border border-[orange] text-[orange]"
                        >
                          <IoLogOut size={18}/>
                        </button>
                      </div>
                    </div>
                    {showHidden ? (
                      <button onClick={() => {
                        setShowHidden(false)
                        getProducts(userData.user_id)
                      }} className="rounded-full font-semibold text-[white] pr-3 pt-1 pb-1 pl-3 bg-[orange]">
                        {getLabels().library.show_normal}
                      </button>
                    ):(
                      <button onClick={() => {
                        setShowHidden(true)
                        getHiddenProducts(userData.user_id)
                      }} className="rounded-full font-semibold text-[white] pr-3 pt-1 pb-1 pl-3 bg-[orange]">
                        {getLabels().library.show_hidden}
                      </button>
                    )}
                  </>
                ):(
                  <button onClick={() => handleRedirectToOwnPage()}
                    className="rounded-full font-semibold text-lg text-[white] pr-3 pt-1 pb-1 pl-3"
                    style={{ backgroundColor: userData.color }}
                  >
                    {getLabels().bottom_header.your_profile}
                  </button>
                )
              ):(
                <button 
                  onClick={() => navigate("/create")} 
                  className="rounded-full font-semibold text-lg text-[white] pr-3 pt-1 pb-1 pl-3"
                  style={{ backgroundColor: userData.color }}
                >
                  {getLabels().bottom_header.start}
                </button>
              )}
            </div>
            <div className="z-30 w-screen bmenupadding right-0 left-0 none-flex justify-around items-center bottom-2 fixed">
              {loggedIn ? (
                isEditable ? (
                  <div>
                    <div className="rounded-full gap-4 flex border-[#373D52] border text-[orange] pr-3 pt-1 pb-1 pl-3 bg-[#1e283a]">
                      <div className="flex gap-2">
                        <button 
                          onClick={() => setVisibleModal('share')} 
                          className="p-2 transition-all hover:bg-opacity-30 hover:text-[orange] hover:bg-[orange] 
                                     rounded-full text-[orange] border-[orange] border"
                        >
                          <FaShare size={24}/>
                        </button>
                        <button 
                          onClick={() => navigate("/" + userData?.library_name + "/analytics")} 
                          className="p-2 hover:bg-opacity-30 transition-all hover:text-[orange] hover:bg-[orange] 
                          rounded-full border border-[orange] text-[orange]"
                        >
                          <MdAutoGraph size={24}/>
                        </button>
                      </div>
                      <div className="flex gap-2">
                        <button 
                          onClick={() => setVisibleModal("create")} 
                          className="p-2 transition-all hover:text-[orange] hover:bg-opacity-30 
                                     border border-[orange] rounded-full bg-[orange] text-[white]"
                        >
                          <IoAddSharp size={24}/>
                        </button>
                      </div>
                      <div className="flex gap-2">
                        <button 
                          onClick={() => setVisibleModal("account")} 
                          className="p-2 transition-all hover:text-[orange] hover:text-[orange] hover:bg-[orange] 
                                     hover:bg-opacity-30 rounded-full border border-[orange] text-[orange]"
                        >
                          <IoSettings size={24}/>
                        </button>
                        <button 
                          onClick={() => {
                            eraseCookie("jwt-token")
                            navigate(0)
                          }} 
                          className="p-2 transition-all hover:bg-opacity-30 hover:text-[orange] hover:bg-[orange] 
                                     rounded-full border border-[orange] text-[orange]"
                        >
                          <IoLogOut size={24}/>
                        </button>
                      </div>
                    </div>
                    <div className="flex m-2 justify-around">
                      {showHidden ? (
                        <button onClick={() => {
                          setShowHidden(false)
                          getProducts(userData.user_id)
                        }} className="rounded-full text-lg text-[white] pr-3 pt-1 pb-1 pl-3 font-semibold bg-[orange]">
                          {getLabels().library.show_normal}
                        </button>
                      ):(
                        <button onClick={() => {
                          setShowHidden(true)
                          getHiddenProducts(userData.user_id)
                        }} className="rounded-full font-semibold text-lg text-[white] pr-3 pt-1 pb-1 pl-3 bg-[orange]">
                          {getLabels().library.show_hidden}
                        </button>
                      )}
                    </div>
                  </div>
                ):(
                  <button onClick={() => handleRedirectToOwnPage()}
                    className="rounded-full font-semibold text-lg text-[white] pr-3 pt-1 pb-1 pl-3"
                    style={{ backgroundColor: userData.color }}
                  >
                    {getLabels().bottom_header.your_profile}
                  </button>
                )
              ):(
                <button 
                  onClick={() => navigate("/create")} 
                  className="rounded-full font-semibold text-lg text-[white] pr-3 pt-1 pb-1 pl-3"
                  style={{ backgroundColor: userData.color }}
                >
                  {getLabels().bottom_header.start}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Library;