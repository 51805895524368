import axios from "axios";
import { useEffect, useState } from "react";
import { FaArrowCircleLeft, FaPaypal } from "react-icons/fa";
import { HiPhotograph } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL, setCookie } from "../../utils/utils";
import { getLabels } from "../../utils/labels";
import { SpinnerCircular } from "spinners-react";

const Signin = () => {

  const [libraryName, setLibraryName] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false)
  const [curr, setCurr] = useState(1);
  const [data, setData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate()
  const [error, setError] = useState("")

  const signin = async (e: any) => {
    setIsLoading(true)
    e.preventDefault()
    await axios.post(BASE_URL + "/users/signin", data)
    .then((res) => {
      setCookie("jwt-token", res.data.param.token, res.data.param.expires_in)
      navigate("/" + res.data.param.library_name)
      navigate(0)
    })
    .catch((err) => {
      console.log(err);
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const handleData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }
  
  return (
    <>
      <div className="flex bg-[#030816] justify-around items-center w-screen h-screen">
        <div className="border border-[#373D52] bg-[#1e283a] z-20 p-8 rounded-lg">
          <h2 className="font-semibold text-[white] text-xl">{getLabels().signin.title}</h2>
          <form onSubmit={(e) => signin(e)}>
            <p className="text-sm max-w-64 text-[#95a3b9]">{getLabels().signin.description}</p>
            <div className="mt-6">
              <input 
                onChange={(e) => handleData(e)} 
                value={data.email} 
                name="email" 
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-8 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                placeholder={getLabels().signin.email}
                type="text" 
              />
              <input
                onChange={(e) => handleData(e)} 
                value={data.password} 
                name="password"
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-8 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                placeholder="********"
                type="password"
              />
            </div>
            <div className="mt-2">
              <p className="text-[red] text-xs">{error}</p>
            </div>
            <div className="mt-8">
              {isLoading ? (
                <button 
                  disabled={true}
                  className="transition-all w-full flex justify-around items-center bg-[orange] text-[orange] bg-opacity-30 border-2 
                            border-[orange] font-semibold pl-4 pr-4 p-2 rounded-lg"
                >
                  <SpinnerCircular speed={243} thickness={214} size={24} color="orange"/>
                </button>
              ):(
                <button
                  disabled={data.email == "" || data.password == ""}
                  className="transition-all w-full text-sm hover:text-[orange] hover:bg-opacity-30 border-2
                              border-[orange] font-semibold pl-6 pr-6 text-[white] p-2 bg-[orange] rounded-lg"
                  >
                    {getLabels().signin.signin}
                </button>
              )}
              
              <div className="mt-4 flex justify-around">
                <div>
                  <p onClick={() => navigate("/recover")} className="cursor-pointer text-center text-[#95a3b9] text-sm underline decoration-[orange]">{getLabels().signin.forgot_password}</p>
                  <p onClick={() => navigate("/create")} className="text-sm cursor-pointer text-center text-[#95a3b9]">{getLabels().signin.create_account}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Signin;