import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { MdArrowDownward, MdArticle, MdOutlineBook } from "react-icons/md";
import Question from "../../components/question";
import Review from "../../components/review";
import Footer from "../../components/footer";
import './index.css';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { BASE_URL, isLoggedIn, libraries } from "../../utils/utils";
import axios from "axios";
import PlanCard from "../../components/plan";
import { Helmet } from "react-helmet";
import { Plan } from "../../typos";
import { getLabels } from "../../utils/labels";
import { IoAdd, IoCreate, IoReceipt } from "react-icons/io5";
import CreateReviewModal from "../../components/create_review_modal";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Flag from "react-world-flags";
import { RiGuideFill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import Carousel from "../../components/carousel";
import ReactSlider from "react-slider";
import { Slider, createTheme } from "@mui/material";
import { ThemeProvider } from "styled-components";
import { orange } from "@mui/material/colors";

const types: any = {
  3: <IoReceipt color="white" size={14}/>,
  2: <MdOutlineBook color="white" size={14}/>,
  1: <MdArticle color="white" size={14}/>,
  4: <RiGuideFill color="white" size={14}/>
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffa500',
      dark: '#ffa500',
      light: '#ffa500'
    },
    secondary: {
      main: '#ffffff',
      dark: '#ffffff',
      light: '#ffffff'
    }
  }
})

const HomePage = () => {

  const [libraryName, setLibraryName] = useState("")
  const [loggedIn, setLoggedIn] = useState(false)
  const navigate = useNavigate()
  const [showCreateReview, setShowCreateReview] = useState(false)
  const [reviews, setReviews] = useState([])
  const salesRef = React.useRef<any>(null)
  const [exists, setExists] = useState(false)
  const [sales, setSales] = useState(340)
  const [salesCost, setSalesCost] = useState(12.99)
  const questionsRef = React.useRef<any>(null)
  const plansRef = React.useRef<any>(null)
  const [plans, setPlans] = useState<Plan[]>([])
  const [loaded, setLoaded] = useState(false)

  const getPlans = async () => {
    await axios.get(BASE_URL + "/plans")
    .then((res) => {
      setPlans(res.data.param)
      setLoaded(true)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleAuth = async () => {
    const loggedIn_ = await isLoggedIn()
    setLoggedIn(loggedIn_)
  }

  const getReviews = async () => {
    await axios.get(BASE_URL + "/reviews")
    .then((res) => {
      setReviews(res.data.param)
      //getPlans()
      setLoaded(true)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    handleAuth()
    getReviews()
  }, [])

  const existsLibrary = async () => {
    await axios.get(BASE_URL + "/users/exists?libraryName=" + libraryName)
    .then((res) => {
      setExists(res.data.param)
      if (res.data.param) {
        return
      }
      navigate("/create?libraryName=" + libraryName)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handlePlanClick = async () => {
    const loggedIn = await isLoggedIn()
    if (loggedIn) {
      return
    }
    navigate("/create")
  }

  function formatNumber(input: number) {
    const strippedInput = input.toString().replace(/,/g, '');
    const formattedNumber = parseFloat(strippedInput).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formattedNumber;
  }
  
  return (
    <div className="bg-[#030816] left-0 right-0 top-0 bottom-0 fixed">
      <Header
        scrollToReviews={() => salesRef?.current.scrollIntoView({ behavior: 'smooth' })}
        scrollToQuestions={() => questionsRef?.current.scrollIntoView({ behavior: 'smooth' })}
        scrollToPlans={() => plansRef?.current.scrollIntoView({ behavior: 'smooth' })}
      />
      <CreateReviewModal onClose={() => setShowCreateReview(false)} visible={showCreateReview}/>
      <div className="overflow-y-scroll pt-44 z-20 bz-30 flex justify-around w-full h-screen relative">
        <div style={{ maxWidth: 745 }} >
          <div className="flex justify-around">
            <div>
              <div className="flex p-4 pb-0 justify-around">
                <div className="flex font-medium text-center justify-around text-md p-2 pr-4 pl-4 bg-[#1e283a] text-[#748194] rounded-full border border-[#373D52]">
                  <p>{getLabels().homepage.top_label}</p>
                </div>
              </div>
              <h1 className="mt-6 max-w-[400px] md:max-w-[800px] tracking-tighter text-center font-bold md:text-6xl text-4xl text-[white]">{getLabels().homepage.claim}
                <span className="inline-flex font-bold ml-0 md:text-[52px] mt-1 text-[34px] pl-4 p-1 pr-4 rounded-full bg-[orange]">{getLabels().homepage.claim_1}</span>
              </h1>
              <h2 className="text-center max-w-[400px] md:max-w-[800px] text-[#95a3b9] md:p-0 pl-4 pr-4 md:mt-6 mt-4 md:text-xl text-md">
                {getLabels().homepage.description}
              </h2>
              <div className="md:mt-14 mt-8 flex justify-around">
                <div className="bg-[#1e283a] items-center flex gap-4 pl-6 pr-2 pt-2 pb-2 rounded-2xl border border-[#373D52]">
                  <div className="flex items-center">
                    <p className="font-semibold text-[white] text-lg">uteka.pages.dev/</p>
                    <input onChange={(e) => setLibraryName(e.target.value)} className="md:w-32 w-24 bg-transparent font-medium text-[orange] outline-none text-lg" placeholder="yourname" type="text" />
                  </div>
                  <button 
                    disabled={libraryName == "" || libraryName.includes(" ")} 
                    onClick={() => existsLibrary()} 
                    className="transition-all hover:text-[orange] hover:bg-opacity-30 border-2 border-[orange] 
                               pl-4 font-semibold pr-4 text-[white] p-2 bg-[orange] rounded-full"
                  >
                    {getLabels().homepage.create_button}
                  </button>
                </div>
              </div>
              <div>
                <p className="text-[red] text-center">
                  {exists ? getLabels().homepage.library_exists_error : null}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-14 p-4">
            <div className="slider md:max-w-[714px] max-w-[340px]">
              <div className="absolute flex w-[344px] justify-between h-[414px] md:w-[714px]">
                <div className="w-24 md:w-40 bg-gradient-to-r from-[#030816] to-transparent h-[414px] z-20"></div>
                <div className="w-24 md:w-40 bg-gradient-to-r from-transparent to-[#030816] h-[414px] z-20"></div>
              </div>
              <div className="slide-track max-w-[400px] md:max-w-[1200px]">
                {libraries.map(library => (
                  <div className="p-2 slide">
                    <div className="flex h-[400px] w-72 p-4 bg-[#1e283a] border border-[#373D52] rounded-lg justify-around">
                      <div>
                        <div className="flex justify-around">
                          <div className="w-16 h-16">
                            <img style={{ borderColor: library.color }} className="object-cover w-full border-2 rounded-full h-full" src={library.image} alt="" />
                          </div>
                        </div>
                        <div className="mt-4">
                          <h1 className="text-[white] text-center font-medium">{library.name}'s library</h1>
                          <h2 className="text-[white] text-center text-sm font-light">{library.description}</h2>
                          <div className="flex justify-around">
                            <div style={{ borderColor: library.color }} className="gap-1 border items-center rounded-full pl-2 flex pr-2 pt-1 pb-1 mt-4">
                              <div className="flex items-center gap-1">
                                <HiOutlineLocationMarker size={18} color={library.color}/>
                                <p className="text-xs text-[white]">{library.city}</p>
                              </div>
                              <div className="ml-1">
                                <Flag className="rounded-xs w-3" code={library.country_code} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 overflow-y-hidden max-h-[164px]">
                          {library.products.map(product => (
                            <div className="p-2">
                              <div className="bg-[#4c546c] p-2 rounded-lg">
                                <div className="rounded-full p-1 pl-4 pr-4 bg-[#1c2434]">
                                  <p className="text-sm text-[white]">{product.name.length > 24 ? product.name.substring(0,24) + "..." : product.name}</p>
                                </div>
                                <div className="flex items-center mt-6 justify-between">
                                  <div className="flex gap-1 items-center">
                                    {types[product.type.type_id]}
                                    <p className="text-sm text-[white]">{product.type.name.charAt(0).toUpperCase() + product.type.name.substring(1)}</p>
                                  </div>
                                  <div>
                                    <div 
                                      className="flex border gap-1 pl-2 text-sm pr-2 items-center text-[white] font-[roboto] rounded-full"
                                      style={{ borderColor: library.color }}
                                    >
                                      <p className="text-md">${product.cost}</p>
                                      <IoIosArrowForward color={library.color} size={18}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div ref={salesRef} className="flex pt-40 p-4 justify-around">
            <div>
              <h1 className="text-[white] text-center md:text-5xl text-3xl font-bold">{getLabels().homepage.sales_title}</h1>
              <div className="flex justify-around">
                <div className="border rounded-lg md:w-[500px] w-[300px] mt-6 p-8 pb-4 pt-4 border-[#373D52]">
                  <div className="flex justify-around">
                    <div className="flex gap-2">
                      <h2 className="text-[orange] text-2xl font-semibold">${formatNumber(sales * salesCost)}</h2>
                      <h2 className="text-[#95a3b9] text-2xl font-semibold">/ month</h2>
                    </div>
                  </div>
                  <p className="text-[#95a3b9] text-lg text-center font-semibold">with {sales} sales</p>
                  <ThemeProvider theme={theme}>
                    <Slider
                      disabled={false}
                      marks={false}
                      max={1500}
                      color="primary"
                      step={10}
                      onChange={(e, v) => setSales(parseInt(v.toString()))}
                      defaultValue={sales}
                      sx={{ 
                        color: theme.palette.primary.main, 
                        '& .MuiSlider-thumb': {
                          backgroundColor: theme.palette.secondary.main,
                          boxShadow: 'none'
                        }
                      }}
                      className="mt-4"
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="flex justify-around mt-4">
                <div>
                  <p className="text-[#95a3b9] text-xl text-center font-semibold">at a cost of</p>
                  <input
                    type="number" 
                    value={salesCost} 
                    step={0.01}
                    placeholder="12.99"
                    onChange={(v) => setSalesCost(parseFloat(v.target.value))}
                    className="text-center md:w-96 w-72 mt-4 md:h-auto h-[54px] focus:outline outline-[orange] font-semibold pl-6 pr-6 p-[12px] text-xl text-[orange] rounded-full bg-[#1e283a]" 
                  />
                  <div className="mt-4">
                    <button 
                      onClick={() => navigate("/create")} 
                      className="transition-all w-full font-semibold hover:text-[orange] hover:bg-opacity-30 border-2 border-[orange] 
                                pl-4 pr-4 text-[white] p-[12px] pl-6 pr-6 bg-[orange] rounded-full"
                    >
                      {getLabels().homepage.get_started}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={questionsRef} className="p-4 pt-40">
            <h1 className="text-[white] text-center md:text-5xl text-3xl font-bold">{getLabels().homepage.frequents_questions}</h1>
            <div className="mt-4">
              {getLabels().homepage.questions.map((question: any) => (
                <Question 
                  question={question.question}
                  answer={question.answer}
                />
              ))}
            </div>
          </div>
          {/*<div ref={plansRef} className="pt-40">
            <h1 className="text-3xl text-center font-semibold font-[roboto]">I nostri piani</h1>
            <div className="mt-4 flex-block">
              {loaded ? (
                plans.map((plan: any) => (
                  <PlanCard onClick={handlePlanClick} plan={plan}/>
                ))
              ): null}
            </div>
          </div>*/}
          <div className="h-40"></div>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
