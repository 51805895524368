import React, { useState } from "react";
import { IoClose, IoReceipt } from "react-icons/io5";
import { MdOutlineBook, MdArticle, MdUnarchive, MdDelete } from "react-icons/md";
import { RiGuideFill } from "react-icons/ri";
import './index.css'
import { FaArchive, FaBook, FaBookmark, FaCloud } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { BASE_URL, getCookie } from "../../utils/utils";
import axios from "axios";
import { Product } from "../../typos";
import { getLabels } from "../../utils/labels";

interface ProductCardProps {
  product: Product,
  onHide: () => void,
  isEditable: boolean,
  color: string,
  onUnHide: () => void,
  onClickToRemove: () => void,
  onClickToBuy?: () => void
}

const types: any = {
  3: <IoReceipt color="#95a3b9" size={14}/>,
  2: <MdOutlineBook color="#95a3b9" size={14}/>,
  1: <MdArticle color="#95a3b9" size={14}/>,
  4: <RiGuideFill color="#95a3b9" size={14}/>
}

const ProductCard: React.FC<ProductCardProps> = ( props ) => {

  const [showDescription, setShowDescription] = React.useState(false)
  const [isHovered, setIsHovered] = React.useState(false)

  const hideProduct = async () => {
    await axios.post(BASE_URL + "/products/hide", { product_id: props.product.product_id }, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then((res) => {
      props.onHide()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const unhideProduct = async () => {
    await axios.post(BASE_URL + "/products/unhide", { product_id: props.product.product_id }, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then((res) => {
      props.onUnHide()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className="pl-0 p-4">
      <div 
        onMouseLeave={() => setIsHovered(false)} onMouseEnter={() => setIsHovered(true)} 
        className="bg-[#1e283a] border-[#373D52] hover:p-5 transition-all min-h-44 boxwidth p-4 rounded-xl border shadow-md"
      >
        <div className="flex justify-between">
          <div className="p-1 rounded-full pl-3 pr-3" style={{ backgroundColor: props.color }}>
            <p className="font-semibold text-[white] text-sm">{ props.product.time_to_read > 60 ? (props.product.time_to_read / 60).toFixed() + " min read" : props.product.time_to_read + " sec read" }</p>
          </div>
        </div>
        <div onClick={() => setShowDescription(!showDescription)} className="transition-all mt-3 bg-opacity-40 cursor-pointer p-3 gap-2 bg-[#030816] rounded-xl">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <FaBook color="#95a3b9"/>
              <p className="font-semibold text-[#95a3b9]">{props.product.title}</p>
            </div>
          </div>
          {showDescription ? (
            <>
              <p className="mt-2 text-[#95a3b9] text-xs font-bold" >• {getLabels().library.description}</p>
              <p className="font-light text-[#95a3b9]">{props.product.description}</p>
            </>
          ): null}
        </div>
        <div className="flex justify-between mt-8 ">
          <div className="flex gap-2 items-center">
            {types[props.product.type.type_id]}
            <p className="text-[#95a3b9]">{props.product.type.name}</p>
          </div>
          <button 
            onClick={props.onClickToBuy}
            style={{ borderColor: props.color }}
            className="flex gap-1 bg-opacity-0 hover:bg-opacity-30 bg-opacity-0 pl-4 pt-1 text-[white] pb-1 pr-4 items-center rounded-full border-2"
          >
            <p className="text-md">€{props.product.cost}</p>
            <IoIosArrowForward color={props.color} size={18}/>
          </button>
        </div>
        {props.isEditable ? (
          isHovered ? (
            props.product.hidden ? (
              <div className="flex mt-6 justify-around">
                <div className="flex gap-2">
                  <button 
                    onClick={unhideProduct}
                    className="border-2 p-1 pl-2 pr-2 items-center flex transition-all hover:bg-[#95a3b9] hover:bg-opacity-20 p-1 rounded-full border-[#95a3b9]"
                  >
                    <MdUnarchive size={13} color="#95a3b9"/>
                    <p className="ml-1 text-[#95a3b9] text-sm">{getLabels().library.show}</p>
                  </button>
                  <button 
                    onClick={props.onClickToRemove} 
                    className="border-2 p-1 pl-2 pr-2 items-center flex transition-all hover:bg-[red] hover:bg-opacity-20 p-1 rounded-full border-[red]"
                  >
                    <MdDelete size={13} color="red"/>
                    <p className="ml-1 text-[red] text-sm">{getLabels().library.remove}</p>
                  </button>
                </div>
              </div>
            ):(
              <div className="flex mt-6 justify-around">
                <button 
                  onClick={hideProduct} 
                  className="border-2 p-1 pl-2 pr-2 items-center flex transition-all hover:bg-[#95a3b9] hover:bg-opacity-20 p-1 rounded-full border-[#95a3b9]"
                >
                  <FaArchive size={13} color="#95a3b9"/>
                  <p className="ml-1 text-[#95a3b9] text-sm">{getLabels().library.hide}</p>
                </button>
              </div>
            )
          ): null
        ): null}
      </div>
    </div>
  );
}

export default ProductCard;