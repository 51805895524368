import axios from "axios";
import "./index.css";
import { useEffect, useState } from "react";
import { FaArrowCircleLeft, FaPaypal } from "react-icons/fa";
import { HiPhotograph } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL, setCookie } from "../../utils/utils";
import { MdDone, MdError } from "react-icons/md";
import { SpinnerCircular } from "spinners-react";
import { getLabels } from "../../utils/labels";

const Create = () => {

  const [libraryName, setLibraryName] = useSearchParams();
  const [curr, setCurr] = useState(1);
  const [data, setData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    bio: '',
    city: '',
    country: '',
    library_name: '',
    image: "",
    paypal_email: ""
  });
  const [emailExists, setEmailExists] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    if(libraryName.has("libraryName")){
      setCurr(2);
      setData({ ...data, library_name: libraryName.get("libraryName") || "" })
    }
  },[])
  const [isPlacesLoading, setIsPlacesLoading] = useState(false)
  const [exists, setExists] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailChecking, setIsEmailCheckLoading] = useState(false)
  const [isPlacesVisible, setIsPlacesVisible] = useState(false)
  const navigate = useNavigate()
  const [searchingPlace, setSearchingPlace] = useState("");
  const [places, setPlaces] = useState([])

  useEffect(() => {
    console.log(data);
  },[data])

  const handleData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const extension = file?.name.split(".")[1]
    
    if (extension !== "png") {
      setError("Il file deve essere un'immagine .png")
      return
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({
          ...data,
          image: reader.result?.toString() || "" // Cast to string since reader.result can be null
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const isEmailValid = (email: string) => {
    return email.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)
  }

  const isPasswordValid = (password: string) => {
    return password.match("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$")
  }
  
  const searchPlaces = async () => {
    await axios.get("https://nominatim.openstreetmap.org/search?q=" + searchingPlace + "&format=json&addressdetails=addressdetails")
    .then((res) => {
      setPlaces(res.data.filter((e: any) => e.addresstype == 'city'))
      setIsPlacesLoading(false)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const signup = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + "/users/signup", { ...data, image: data.image != "" ? data.image.split(",")[1] : ""})
    .then((res) => {
      setCookie("jwt-token", res.data.param.token, res.data.param.expires_in)
      navigate("/" + data.library_name + "?new=true")
      navigate(0)
    })
    .catch((err) => {
      console.log(err);
    })
    setIsLoading(false)
  }

  const existsLibrary = async () => {
    await axios.get(BASE_URL + "/users/exists?libraryName=" + data.library_name)
    .then((res) => {
      setExists(res.data.param)
      if (res.data.param) {
        return
      }
      setCurr(curr + 1)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const existsByEmail = async () => {
    await axios.get(BASE_URL + "/users/exists?email=" + data.email)
    .then((res) => {
      setEmailExists(res.data.param)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    setIsEmailCheckLoading(true)
    const delayDebounceFn = setTimeout(() => {
     existsByEmail()
     setIsEmailCheckLoading(false)
    }, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [data.email])

  useEffect(() => {
    setIsPlacesLoading(true)
    const delayDebounceFn = setTimeout(() => {
     searchPlaces()
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchingPlace])
  
  return (
    <>
      <div className="flex bg-[#030816] justify-around items-center w-screen h-screen">
        <header className="z-30 backdrop-blur-sm top-0 pt-4 w-screen fixed flex justify-around">
          <div></div>
          <button 
            onClick={() => navigate("/signin")} 
            className="transition-all font-semibold hover:bg-opacity-30 text-[orange] hover:bg-[orange] 
                      text-sm pl-3 pr-3 p-1 rounded-full border-2 border-[orange]"
          >
            {getLabels().create.title}
          </button>
        </header>
        <div className="z-20">
          <h2 className="font-semibold text-[white] text-2xl"> {getLabels().create.step} <span className="text-[orange]">{curr}</span>/4</h2>
          {data.library_name !== "" && curr >= 2 ? (
            curr == 2 ? (
              <div>
                <p className="text-lg text-[#95a3b9]">{getLabels().create.description}</p>
                <div className="mt-6 flex-block gap-1">
                  <div className="items-center mbb bg-[#1e283a] border-[#373D52] flex pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                    <input
                      onChange={(e) => handleData(e)}
                      value={data.name}
                      name="name" 
                      className="w-64 text-[#95a3b9] bg-transparent outline-none text-lg" 
                      placeholder={getLabels().create.form.name}
                      type="text"
                    />
                  </div>
                  <div className="items-center bg-[#1e283a] border-[#373D52] flex pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                    <input 
                      onChange={(e) => handleData(e)}
                      value={data.surname} 
                      name="surname" 
                      className="w-64 text-[#95a3b9] bg-transparent outline-none text-lg" 
                      placeholder={getLabels().create.form.surname} 
                      type="text" 
                    />
                  </div>
                </div>
                <div className="mt-2 items-center bg-[#1e283a] border-[#373D52] flex justify-between pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                  <input
                    onChange={(e) => handleData(e)}
                    name="email" value={data.email} 
                    className="w-64 text-[#95a3b9] bg-transparent outline-none text-lg" 
                    placeholder={getLabels().create.form.email}
                    type="text"
                  />
                  {isEmailChecking ? (
                    <SpinnerCircular secondaryColor="#030816" thickness={354} size={18} color="orange"/>
                  ):(
                    emailExists || !isEmailValid(data.email) ? (
                    <MdError color="red" size={18}/>
                    ):(
                      <MdDone color="green" size={18}/>
                    )
                  )}
                </div>
                <div className="mt-2 items-center bg-[#1e283a] border-[#373D52] flex pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                  <input 
                    onChange={(e) => handleData(e)} 
                    name="password" value={data.password} 
                    className="w-64 text-[#95a3b9] bg-transparent outline-none text-lg" 
                    placeholder={getLabels().create.form.password}
                    type="password"
                  />
                </div>
                <div className="mt-2">
                  <div className="flex items-center">
                    {data.password.length > 8 && data.password.length <= 16 ? (
                      <MdDone color="green" size={18}/>
                    ):(
                      <MdError color="red" size={18}/>
                    )}
                    <p className="ml-2 text-[#95a3b9] text-sm">{getLabels().create.form.password_c1}</p>
                  </div>
                  <div className="flex items-center">
                    {data.password.match(/\d+/) ? (
                      <MdDone color="green" size={18}/>
                    ):(
                      <MdError color="red" size={18}/>
                    )}
                    <p className="ml-2 text-[#95a3b9] text-sm">{getLabels().create.form.password_c2}</p>
                  </div>
                  <div className="flex items-center">
                    {data.password.match(/[A-Z]/) ? (
                      <MdDone color="green" size={18}/>
                    ):(
                      <MdError color="red" size={18}/>
                    )}
                    <p className="ml-2 text-[#95a3b9] text-sm">{getLabels().create.form.password_c3}</p>
                  </div>
                </div>
                <div className="mt-4 flex justify-between">
                  <div></div>
                  <button
                    disabled={data.name == "" || data.surname == "" || isEmailChecking || emailExists || !isEmailValid(data.email) || !isPasswordValid(data.password)}
                    onClick={() => setCurr(curr + 1)}
                    className="transition-all font-semibold hover:text-[orange] hover:bg-opacity-30 border-2
                               border-[orange] pl-6 pr-6 text-[white] p-2 bg-[orange] rounded-lg"
                    >
                      {getLabels().create.next}
                  </button>
                </div>
              </div>
            ) : (
              curr == 3 ? (
                <div>
                  <p className="text-lg text-[#95a3b9]">{getLabels().create.description}</p>
                  <div className="mt-4 flex-block items-center">
                    <div className="block-flex justify-around items-center">
                      <label className="cursor-pointer border border-[#373D52] bg-[#1e283a] w-32 h-32 flex justify-around items-center rounded-full">
                        <input className="hidden" onChange={(e) => handleFile(e)} accept=".png" type="file"/>
                        {data.image !== "" ? (
                          <img className="w-full h-full object-cover rounded-full" src={data.image} alt="" />
                        ):(
                          <HiPhotograph color="#95a3b9" size={45}/>
                        )}
                      </label>
                      <p className="text-[red] max-w-32" >{error}</p>
                    </div>
                    <div className="ml-4">
                      <div className="items-center bg-[#1e283a] border-[#373D52] flex pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                        <input 
                          onChange={(e) => handleData(e)} 
                          name="bio" 
                          value={data.bio} 
                          className="w-64 text-[#95a3b9] bg-transparent outline-none text-lg" 
                          placeholder={getLabels().create.form.description}
                          type="text" 
                        />
                      </div>
                      <div className="mt-3 items-center bg-[#1e283a] border-[#373D52] flex pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                        <input value={searchingPlace} onChange={(e) => {
                          setSearchingPlace(e.target.value)
                          setIsPlacesVisible(true)
                        }} className="w-64 text-[#95a3b9] bg-transparent outline-none text-lg" placeholder={getLabels().create.form.city} type="text" />
                      </div>
                      <div className="border bg-[#1e283a] border border-[#373D52] rounded-b-lg">
                        {isPlacesLoading ? (
                          <div className="p-4">
                            <p className="text-md text-[#95a3b9]">{getLabels().create.loading}</p>
                          </div>
                        ):(
                          isPlacesVisible ? (
                            places.map((e: any) => (
                              <div onClick={() => {
                                setIsPlacesVisible(false)
                                setData({ ...data, country: e.address.country_code, city: e.name })
                                setSearchingPlace(e.name + ", " + e.address.country_code.toUpperCase())
                              }} className="p-2 text-[#95a3b9] hover:bg-[#373D52] hover:bg-opacity-20 cursor-pointer">
                                <p>{e.name}, {e.address.country}</p>
                              </div>
                            ))
                          ) : null
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div></div>
                    <button 
                      disabled={
                        data.bio == "" || 
                        data.country == "" || 
                        data.city == ""
                      } 
                      onClick={() => setCurr(curr + 1)} 
                      className="transition-all font-semibold hover:text-[orange] hover:bg-opacity-30 border-2 border-[orange] 
                                 pl-6 pr-6 text-[white] p-2 bg-[orange] rounded-lg"
                    >
                      {getLabels().create.next}
                    </button>
                  </div>
                </div>
              ):(
                <div>
                  <p className="text-lg max-w-72 text-[#95a3b9]">{getLabels().create.paypal_description}</p>
                  <div className="bg-[#1e283a] border-[#373D52] rounded-xl border pt-2 pl-4 pr-4 pb-2 mt-6 flex justify-between">
                    <input 
                      onChange={(e) => handleData(e)} 
                      name="paypal_email" 
                      value={data.paypal_email}
                      placeholder="email@example.com" 
                      className="mr-2 text-[#95a3b9] bg-transparent outline-none" 
                      type="text" 
                    />
                    {isLoading ? (
                      <button 
                        disabled={true}
                        className="transition-all bg-[orange] text-[orange] bg-opacity-30 border-2 
                                  border-[orange] font-semibold pl-4 pr-4 p-2 rounded-lg"
                      >
                        <SpinnerCircular speed={243} thickness={214} size={24} color="orange"/>
                      </button>
                    ):(
                      <button 
                        disabled={!isEmailValid(data.paypal_email)} 
                        onClick={() => signup()} 
                        className="transition-all font-semibold items-center bg-[orange] flex text-[orange] bg-opacity-30 
                                  pl-4 pr-4 p-2 border-2 border-[orange] rounded-lg"
                      >
                        {getLabels().create.next}
                        <FaPaypal className="ml-2"/>
                      </button>
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <>
              <div className="mt-6 bg-[#1e283a] border-[#373D52] items-center flex gap-4 pl-6 pr-2 pt-2 pb-2 rounded-2xl border">
                <div className="flex">
                  <p className="text-lg font-semibold text-[white]">uteka.pages.dev/</p>
                  <input
                    onChange={(e) => handleData(e)} 
                    value={data.library_name} 
                    name="library_name" 
                    className="w-24 bg-transparent font-medium text-[#95a3b9] outline-none text-lg" 
                    placeholder="yourname" 
                    type="text"
                  />
                </div>
                <button 
                  disabled={data.library_name == ""} 
                  onClick={() => existsLibrary()} 
                  className="transition-all font-semibold hover:text-[orange] hover:bg-opacity-30 border-2 border-[orange] 
                             pl-4 pr-4 text-[white] p-2 bg-[orange] rounded-full"
                >
                  Avanti
                </button>
              </div>
              <div>
                <p className="text-[red] text-center">
                  {exists ? getLabels().create.library_exists_error : null}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Create;