import { FaDiscord, FaGithub, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { RiThreadsFill, RiTwitterXFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="p-14 pb-24 bottom-0 relative flex justify-around">
      <div className="flex-block items-center gap-24">
        <div className="flex items-center gap-1">
          <div className="w-10 h-10">
            <img className="object-cover w-full h-full" src={require("../../images/new_new_logo_dark.png")} alt="" />
          </div>
          <p className="ml-2 text-[white]">© 2024 Uteka.</p>
        </div>
        <div className="flex-none justify-around text-xl gap-2 text-[orange]">
          <a target="__blank" href="https://x.com/Uteka_Official"><RiTwitterXFill/></a>
          <a target="__blank" href="https://discord.gg/H6cndhYNyE"><FaDiscord/></a>
          <a target="__blank" href="https://instagram.com/Uteka_Official"><FaInstagram/></a>
        </div>
        <div className="none-flex mt-4 flex justify-around text-2xl gap-2 text-[orange]">
          <a target="__blank" href="https://x.com/Uteka_Official"><RiTwitterXFill/></a>
          <a target="__blank" href="https://discord.gg/H6cndhYNyE"><FaDiscord/></a>
          <a target="__blank" href="https://instagram.com/Uteka_Official"><FaInstagram/></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;