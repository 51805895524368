import React from "react";
import { FaCopy, FaFacebook, FaLinkedin, FaStar, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdClose, MdDelete, MdDone, MdEmail } from "react-icons/md";
import { RiEditFill, RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  FacebookIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { getLabels } from "../../utils/labels";
import axios from "axios";
import { BASE_URL, getCookie } from "../../utils/utils";
import { Product } from "../../typos";

interface DeleteModalProps {
  visible: boolean,
  product: Product | undefined
  onClose: (articleRemoved: boolean) => void
}

const DeleteModal: React.FC<DeleteModalProps> = ( props ) => {

  const deleteProduct = async () => {
    await axios.delete(BASE_URL + "/products/" + props.product?.product_id, {
      headers: {
        Authorization: "Bearer " + getCookie("jwt-token")
      }
    })
    .then((res) => {
      props.onClose(true)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  if (!props.visible) {
    return null;
  }

  return (
    <div className="top-0 left-0 z-40 w-screen backdrop-blur-sm bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div className="p-6">
        <div style={{ maxWidth: 324 }} className="p-6 rounded-lg border border-[#373D52] bg-[#1e283a]">
          <div className="flex justify-between">
            <div></div>
            <button
              onClick={() => {
                props.onClose(false)
              }}
              className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#373D52] transition-all"
            >
              <IoClose size={24} color="gray"/>
            </button>
          </div>
          <div>
            <p className="text-md text-[#95a3b9]"> {getLabels().delete_modal.description.replace("{product_name}", props?.product?.title || "")}</p>
            <div className="flex justify-between mt-6">
              <div></div>
              <div className="flex gap-4">
                <button 
                  onClick={() => props.onClose(false)}
                  className="transition-all hover:bg-opacity-30 text-[orange] hover:bg-[orange] text-md 
                  pl-3 pr-3 p-1 rounded-full border-2 border-[orange]"
                >
                  {getLabels().delete_modal.back}
                </button>
                <button 
                  onClick={deleteProduct}
                  className="p-1 pl-4 pr-4 items-center flex transition-all hover:bg-opacity-60 bg-[red] p-1 rounded-full"
                >
                  <p className="ml-1 font-medium text-[white] text-md">{getLabels().delete_modal.remove}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;