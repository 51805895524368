import React from "react";
import { FaCopy, FaFacebook, FaLinkedin, FaReddit, FaStar, FaTelegram, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";
import { IoClose, IoCopy } from "react-icons/io5";
import { MdClose, MdDone, MdEmail } from "react-icons/md";
import { RiEditFill, RiTelegramFill, RiTwitterXFill, RiTwitterXLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  FacebookIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { getLabels } from "../../utils/labels";

interface ShareModalProps {
  visible: boolean,
  url: string,
  onClose: () => void
}

const ShareModal: React.FC<ShareModalProps> = ( props ) => {

  const navigate = useNavigate()
  const [copied, setCopied] = React.useState(false)

  if (!props.visible) {
    return null;
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(props.url)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2400)
  }

  return (
    <div className="top-0 left-0 z-40 w-screen backdrop-blur-sm bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div className="p-6">
        <div style={{ maxWidth: 314 }} className="p-6 rounded-lg border border-[#373D52] bg-[#1e283a]">
          <div className="flex justify-between">
            <div></div>
            <button 
              onClick={() => {
                props.onClose()
              }}
              className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#373D52] transition-all"
            >
              <IoClose size={24} color="#95a3b9"/>
            </button>
          </div>
          <div className="mt-2">
            <h2 className="text-[white] text-xl font-semibold"> {getLabels().share_modal.title}</h2>
            <p className="text-sm mt-1 text-[#95a3b9]"> {getLabels().share_modal.description}</p>
            <div className="flex justify-around mt-6 gap-2">
              <div className="flex gap-3">
                <TelegramShareButton url={props.url} >
                  <div className="p-3 rounded-full hover:text-[orange] hover:bg-opacity-50 transition-all text-xl bg-opacity-30 text-[#95a3b9] bg-[#373D52]">
                    <FaTelegramPlane/>
                  </div>
                </TelegramShareButton>
                <WhatsappShareButton url={props.url} >
                  <div className="p-3 rounded-full hover:text-[orange] hover:bg-opacity-50 transition-all text-xl bg-opacity-30 text-[#95a3b9] bg-[#373D52]">
                    <FaWhatsapp/>
                  </div>
                </WhatsappShareButton>
                <TwitterShareButton url={props.url} >
                  <div className="p-3 rounded-full hover:text-[orange] hover:bg-opacity-50 transition-all text-xl bg-opacity-30 text-[#95a3b9] bg-[#373D52]">
                    <RiTwitterXLine/>
                  </div>
                </TwitterShareButton>
                <FacebookShareButton url={props.url} >
                  <div className="p-3 rounded-full hover:text-[orange] hover:bg-opacity-50 transition-all text-xl bg-opacity-30 text-[#95a3b9] bg-[#373D52]">
                    <FaFacebook/>
                  </div>
                </FacebookShareButton>
                <RedditShareButton url={props.url} >
                  <div className="p-3 rounded-full hover:text-[orange] hover:bg-opacity-50 transition-all text-xl bg-opacity-30 text-[#95a3b9] bg-[#373D52]">
                    <FaReddit/>
                  </div>
                </RedditShareButton>
              </div>
            </div>
            <div className="flex justify-around mt-4 gap-2">
              <div>
                <p className="text-sm text-center font-medium text-[#95a3b9]">or share with</p>
                <div className="rounded-md w-52 justify-between bg-[#030816] bg-opacity-20 p-3 mt-2 items-center flex border border-[#373D52]">
                  <p className="text-[#95a3b9] text-sm">{props.url.split("//")[1]}</p>
                  {copied ? (
                    <MdDone color="orange"/>
                  ):(
                    <button className="hover:text-[orange] transition-all text-[#95a3b9]" onClick={handleCopy}>
                      <IoCopy/>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;