import React from "react";
import { FaStar } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface PremiumModalProps {
  visible: boolean,
  name: string,
  onClose: () => void
}

const PremiumModal: React.FC<PremiumModalProps> = ( props ) => {

  const navigate = useNavigate()

  if (!props.visible) {
    return null;
  }

  return (
    <div className="top-0 left-0 z-40 w-screen bg-[black] bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div className="p-6">
        <div className="p-6 rounded-lg bg-[white]">
          <div className="flex justify-between">
            <div></div>
            <button 
              onClick={() => {
                props.onClose()
              }}
              className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#e4e4e4] transition-all"
            >
              <IoClose size={24} color="gray"/>
            </button>
          </div>
          <div className="flex items-center gap-4">
            <FaStar color="orange" size={34}/>
            <p className="font-[roboto] font-regular text-lg" style={{ maxWidth: 284 }}>
              {props.name} è un utente Premium e usufruisce dei vantaggi, 
              <span onClick={() => navigate("/")} className="underline cursor-pointer decoration-[orange]">Scopri</span> 
              di più sul piano Premium
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PremiumModal;