import { getLabels } from "../../utils/labels";

const NotFound = () => {
   
  return (
    <div className="bg-[#030816] top-0 fixed">
      <div className="p-14 z-20 bz-30 flex justify-around items-center w-screen h-screen relative">
        <div>
          <div className="flex border-b-4 pb-2 border-[orange] justify-around">
            <p className="font-extrabold text-6xl text-[orange]">404</p>
          </div>
          <p className="text-[white] text-md text-center mt-3 max-w-96 font-regular">{getLabels().not_found.name}</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;