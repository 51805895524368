import { useState } from "react"
import { MdArrowDownward, MdArrowUpward } from "react-icons/md"

interface QuesitonProps {
  question: string,
  answer: string
}

const Question: React.FC<QuesitonProps> = ( props ) => {

  const [shown, setShown] = useState(false)

  return (
    <div className="pl-0 p-2">
      <div onClick={() => setShown(!shown)} className="flex cursor-pointer border-[#373D52] bg-[#1e283a] text-[#95a3b9] p-4 gap-1 border rounded-lg items-center">
        {shown ? <MdArrowUpward color="orange"/> : <MdArrowDownward color="orange"/>}
        <p>{props.question}</p>
      </div>
      {shown ? (
        <div className="border-b border-r border-l bg-opacity-80 p-4 rounded-b-lg border-[#373D52] bg-[#1e283a]">
          <p className="text-[#95a3b9]">{props.answer}</p>
        </div>
      ) : null}
    </div>
  )
}

export default Question