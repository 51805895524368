import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from "axios";
import React from "react";
import { BASE_URL, getCookie } from "../../utils/utils";
import { IoClose } from "react-icons/io5";
import { Product } from "../../typos";

interface OrderProps {
  product: Product | undefined,
  visible: boolean,
  onClose: () => void,
  payee_paypal_email: string
}

const Order: React.FC<OrderProps> = ( props ) => {

  const createOrder = async () => {
    await axios.post(BASE_URL + "/orders/", {
      product_id: props.product?.product_id,
    }, {
      headers: {
        "Authorization": "Bearer " + getCookie("jwt-token"),
      }
    })
    .then((res) => {
      window.location.href = props.product?.file_path + "?tk=" + res.data.param.tk
    })
    .catch((error) => {
      console.log(error);
    })
  }

  if (!props.visible) {
    return null;
  }

  return (
    <div className="top-0 left-0 z-40 w-screen bg-[black] bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div style={{ maxWidth: 314 }} className="p-6 rounded-lg border border-[#373D52] bg-[#1e283a]">
        <div className="flex justify-between">
          <div className="flex items-center">
            <span className="p-1 pl-2 pr-2 bg-[orange] text-[white] rounded-md font-semibold text-[gray] text-xs">Checkout</span>
          </div>
          <button 
            onClick={() => {
              props.onClose()
            }}
            className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#373D52] transition-all"
          >
            <IoClose size={24} color="gray"/>
          </button>
        </div>
        <div className="pb-2 border-b border-[#373D52] mt-3">
          <p className="font-semibold text-lg text-[white]">{props.product?.title}</p>
          <p className="font-regular text-sm text-[#95a3b9]">{props.product?.description}</p>
        </div>
        <div className="mt-6">
          <PayPalScriptProvider options={{ "clientId": process.env.REACT_APP_PAYPAL_CID || "" }}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  intent: 'CAPTURE',
                  purchase_units: [
                    {
                      amount: {
                        currency_code: "USD",
                        value: props.product?.cost.toString() || "",
                      },
                      payee: {
                        email_address: props.payee_paypal_email
                      },
                      description: "Uteka: " + props.product?.title,
                    },
                  ],
                });
              }}
              onApprove={async (data: any, actions: any) => {
                const details = await actions.order.capture();
                console.log(details)
                createOrder()
              }}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
}

export default Order;