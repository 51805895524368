
const LANGUAGE = 'en'

export const getLabels = () => {
    const LABELS = {
        it: {
            homepage: {
                top_label: "🚀 Miglior scelta per creatori digitali",
                claim: "Crea la tua libreria digitale in meno di ",
                claim_1: "5 minuti",
                library_exists_error: "Questa libreria esiste già, prova un altro nome",
                description: "Crea la tua libreria digitale di valore con guide, articoli, ricette ed e-books personalizzati, e condividila facilmente con la tua community tramite un link, guadagna più di 2.000$ al mese con la tua passione.",
                create_button: "Crea",
                get_started: "Inizia ora",
                sales_title: "Potresti guadagnare",
                frequents_questions: "Domande frequenti",
                questions: [
                    {
                        "question": "Come posso condividere la mia libreria digitale?",
                        "answer": "La tua libreria digitale è condivisibile tramite un link che puoi manualmente copiare e mettere nelle biografie dei tuoi social media ad esempio, come fa l'83% dei nostri utenti. Oppure puoi premere il tasto condividi nella pagina della tua libreria per condividerla ovunque tu voglia."
                    },
                    {
                        "question": "Vengo pagato in tempo reale?",
                        "answer": "Si, collegando il tuo account paypal gli acquisti dei tuoi prodotti arriveranno direttamente sul tuo saldo paypal in tempo reale."
                    },
                    {
                        "question": "Posso vendere alcun tipo di file?",
                        "answer": "No, i prodotti che venderai dovranno necessariamente essere in formato PDF per facilitare il download e la lettura dei compratori."
                    },
                    {
                        "question": "Riceverò in tempo reale un prodotto che acquisto?",
                        "answer": "Si, appena compri un prodotto inizierà il download di esso."
                    },
                    {
                        "question": "E' possibile tenere traccia delle vendite?",
                        "answer": "Si, ogni libreria digitale ha una sezione chiamata Analytics dove è possibile monitorare le vendite e informazioni utili tramite testo e grafici intuititivi"
                    },
                    {
                        "question": "Ho bisogno di un account per comprare prodotti?",
                        "answer": "No, non hai bisogno di un account per comprare prodotti di altri utenti ma ne avrai bisogno se vuoi iniziare a vendere i tuoi prodotti."
                    },
                    /*{
                        "question": "Qual è la differenza tra il piano FREE e il piano Premium?",
                        "answer": "Il piano FREE ti permette di creare la tua libreria digitale, pubblicare fino a 4 prodotti e monitorare le vendite ma senza l'utilizzo di grafici. Il piano Premium permette innanzitutto di pubblicare quanti prodotti tu voglia, poi è un'ottima scelta per monitorare le vendite utilizzano grafici di alta qualità."
                    },
                    {
                        "question": "Posso provare la vostra piattaforma gratuitamente prima di decidere di sottoscrivere un piano?",
                        "answer": "Si! La nostra piattaforma può essere usata gratuitamente per sempre! Se sentirai il bisogno di passare al piano successivo puoi farlo in qualsiasi momento."
                    }*/
                ]
            },
            header: {
                eranings: "Guadagni",
                faq: "Domande",
                signup: "Inizia gratis",
                signin: "Accedi"
            },
            live_url: {
                title: 'Congratulazioni!',
                label: 'La tua libreria digitale è online, ecco il link per vederla in diretta'
            },
            review_modal: {
                content: "Cosa ne pensi di Uteka?",
                create: "Crea",
                content_placeholder: "Scrivi qui la tua recensione...",
                stars: "Quanto valuteresti Uteka?",
                created: "Grazie per la tua recensione, speriamo ti piaccia utilizzare Uteka!",
                exit: "Chiudi"
            },
            create: {
                step: "Passaggio",
                title: "Accedi",
                library_exists_error: "Questa libreria esiste già, prova un altro nome",
                loading: "Caricamento...",
                description: "Inserisci i tuoi dati per creare la tua libreria digitale",
                paypal_description: "Collega il tuo account paypal per ricevere i pagamenti",
                form: {
                    name: "Nome",
                    surname: "Cognome",
                    email: "Email",
                    description: "Breve descrizione di te",
                    city: "In che città vivi?",
                    password: "Password",
                    password_c1: "Lunghezza compresa tra 8 e 16 caratteri",
                    password_c2: "Almeno la presenza di un carattere numerico",
                    password_c3: "Almeno la presenza di un carattere maiuscolo",
                },
                next: "Avanti",

            },
            signin: {
                title: "Accesso",
                description: "Inserisci i tuoi dati per accedere alla tua libreria digitale",
                email: "Email",
                forgot_password: "Password dimenticata?",
                create_account: "Inizia",
                signin: "Accedi"
            },
            recover: {
                title: "Recupera la tua password",
                description: "Inserisci la tua email per recuperare la tua password",
                email: "Email",
                recover: "Recupera"
            },
            delete_modal: {
                description: "Vuoi davvero eliminare deifinitvamente: '{product_name}'?",
                remove: "Elimina",
                back: "Annulla"
            },
            create_password: {
                title: "Recupera la tua password",
                description: "Inserisci la tua nuova password",
                password: "Password",
                password_confirmation: "Ripeti la password",
                next: "Avanti"
            },
            library: {
                show_hidden: "Mostra nascosti",
                show_normal: "Mostra normali",
                hide: "Nascondi",
                show: "Mostra",
                remove: "Elimina",
                description: "DESCRIZIONE"
            },
            share_modal: {
                title: "Condividi la tua libreria digitale",
                description: "Condividi la tua libreria digitale per arrivare a più persone possibili"
            },
            create_modal: {
                name: "Nome",
                title: "Crea un Prodotto",
                label: "Inserisci qui i dati del tuo prodotto",
                pick: "Scegli un file",
                cost: "Price",
                description: "Breve Descrizione",
                create: "Crea",
                extension_error: "Il file deve essere in formato PDF"
            },
            account_modal: {
                name: "Nome",
                surname: "Cognome",
                paypal_email: "Email Paypal",
                city: "Città",
                description: "Breve Descrizione",
                change: "Modifica",
                extension_error: "Il file deve essere un'immagine in formato .png"
            },
            maintenance: {
                maintenance: "Uteka è in ",
                maintenance_1: "manutenzione...",
                description: "Stiamo migliorando la piattaforma e ci scusiamo per la momentanea assenza, torneremo con nuove funzionalità nel minor tempo possibile, nel frattempo prepara le tue opere digitali",
            },
            not_found: {
                name: "Pagina non trovata"
            },
            library_analytics: {
                available_products: "Prodotti disponibili",
                total_earnings: "Guadagni",
                no_mobile_available: "Non è possibile vedere questa pagina su un dispositivo mobile",
                days: "giorni",
                total_sales: "Prodotti venduti",
                created_on: "Iscritto da",
                sales: "Le tue vendite",
                earnings: "I tuoi guadagni",
                today: "Oggi",
                week: "Questa settimana",
                month: "Questo mese",
                year: "Quest'anno"
            },
            bottom_header: {
                start: "Inizia",
                your_profile: "Vai al tuo profilo",
                come_back: "Torna al tuo profilo"
            }
        },
        en: {
            homepage: {
                top_label: "🚀 Best choice for digital creators",
                claim: "Create your digital library in less than ",
                claim_1: "5 minutes",
                library_exists_error: "This library already exists, try another name",
                description: "Build your valuable digital library with personalized guides, articles, recipes, and e-books, share it with your community via a link and earn more than 2.000$ a month with your passion.",
                create_button: "Create",
                get_started: "Get started",
                sales_title: "You could be earning",
                frequents_questions: "Frequently Asked Questions",
                questions: [
                    {
                      "question": "How can I share my digital library?",
                      "answer": "Your digital library can be shared via a link or you can put it into your social media bios, as the most of our users do."
                    },
                    {
                      "question": "Do I get paid in real time?",
                      "answer": "Yes, by linking your PayPal account, purchases of your products will be sent directly in your PayPal account in real time."
                    },
                    {
                      "question": "Can I sell any type of file?",
                      "answer": "No, the products you sell must necessarily be in PDF format to facilitate downloading and reading by buyers."
                    },
                    {
                      "question": "Will I receive a product I purchase in real time?",
                      "answer": "Yes, as soon as you buy a product, its download will begin."
                    },
                    {
                      "question": "Is it possible to track sales?",
                      "answer": "Yes, every digital library has an Analytics section where you can monitor sales and useful information through intuitive text and graphics."
                    },
                    {
                      "question": "Do I need an account to buy products?",
                      "answer": "No, you don't need an account to buy products from other users, but you will need one if you want to start selling your products."
                    },
                    /*{
                      "question": "What is the difference between the FREE plan and the Premium plan?",
                      "answer": "The FREE plan allows you to create your digital library, publish up to 4 products, and monitor sales but without the use of graphs. The Premium plan allows you to publish as many products as you want, and it's an excellent choice for monitoring sales using high-quality graphs."
                    },
                    {
                      "question": "Can I try your platform for free before deciding to subscribe to a plan?",
                      "answer": "Yes! Our platform can be used for free forever! If you feel the need to upgrade to the next plan, you can do so at any time."
                    }*/
                ]
            },
            header: {
                earnings: "Earnings",
                faq: "Questions",
                signup: "Get started",
                signin: "Sign In"
            },
            live_url: {
                title: 'Congratulations!',
                label: 'Your digital library is offcially live, this is your live URL.'
            },
            review_modal: {
                content: "What do you think of Uteka?",
                create: "Create",
                content_placeholder: "Write your review here...",
                stars: "What's your rating for Uteka?",
                created: "Thank you for your review, we hope you enjoy using Uteka!",
                exit: "Close"
            },
            create: {
                step: "Step",
                title: "Sign In",
                loading: "Loading...",
                description: "Enter your details to create your digital library",
                library_exists_error: "This library already exists, try another name",
                paypal_description: "Connect your PayPal account to receive payments",
                form: {
                    name: "Name",
                    surname: "Surname",
                    email: "Email",
                    description: "Short description about yourself",
                    city: "Which city do you live in?",
                    password: "Password",
                    password_c1: "Length between 8 and 16 characters",
                    password_c2: "At least one numeric character",
                    password_c3: "At least one uppercase character"
                },
                next: "Next"
            },
            signin: {
                title: "Sign In",
                description: "Enter your details to access your digital library",
                email: "Email",
                forgot_password: "Forgot Password?",
                create_account: "Get Started",
                signin: "Sign In"
            },
            recover: {
                title: "Recover Your Password",
                description: "Enter your email to recover your password",
                email: "Email",
                recover: "Recover"
            },
            create_password: {
                title: "Create Your New Password",
                description: "Enter your new password",
                password: "Password",
                password_confirmation: "Repeat Password",
                next: "Next"
            },
            library: {
                show_hidden: "Show Hidden",
                show_normal: "Show Normal",
                description: "DESCRIPTION",
                hide: "Hide",
                show: "Show",
                remove: 'Delete'
            },
            share_modal: {
                title: "Share Your Digital Library",
                description: "Share your digital library to reach as many people as possible"
            },
            delete_modal: {
                description: "Are you sure you want to permanently delete: '{product_name}'?",
                remove: "Delete",
                back: "Back"
            },
            create_modal: {
                name: "Name",
                title: "Create a Product",
                label: "Insert the data of your product here",
                pick: "Pick a file",
                cost: "Price",
                description: "Short Description",
                create: "Create",
                extension_error: "File format must be .pdf"
            },
            account_modal: {
                name: "Name",
                surname: "Surname",
                paypal_email: "PayPal Email",
                city: "City",
                description: "Short Description",
                change: "Change",
                extension_error: "File format must be .png"
            },
            maintenance: {
                maintenance: "Uteka is under ",
                maintenance_1: "maintenance...",
                description: "We are improving the platform and apologize for the temporary absence, we will return with new features as soon as possible, in the meantime prepare your digital works"
            },
            not_found: {
                name: "Page Not Found"
            },
            library_analytics: {
                available_products: "Available Products",
                total_earnings: "Earnings",
                no_mobile_available: "This page cannot be viewed on a mobile device",
                days: "days",
                total_sales: "Total Sales",
                created_on: "Joined Since",
                sales: "Your Sales",
                earnings: "Your Earnings",
                today: "Today",
                week: "This Week",
                month: "This Month",
                year: "This Year"
            },
            bottom_header: {
                start: "Start",
                your_profile: "Go to Your Profile",
                come_back: "Return to Your Profile"
            }
        }
    }

    return LABELS[LANGUAGE]
}