import { SpinnerCircular } from "spinners-react";

const LoadingScreen = () => {
  return (
    <div className="top-0 z-40 left-0 w-screen bg-[#030816] h-screen fixed flex justify-around items-center">
      <SpinnerCircular speed={314} secondaryColor="#1e283a" thickness={154} color="orange" size={84}/>
    </div>
  );
}

export default LoadingScreen;