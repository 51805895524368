import React, { useEffect, useState } from "react";
import "./index.css"
import { useNavigate } from "react-router-dom";
import { getUser, isLoggedIn } from "../../utils/utils";
import { User } from "../../typos";
import { getLabels } from "../../utils/labels";

interface HeaderProps {
  scrollToReviews: () => void
  scrollToQuestions: () => void
  scrollToPlans: () => void    
}

const Header: React.FC<HeaderProps> = ( props ) => {

  const navigate = useNavigate()
  const [user, setUser] = useState<User>()
  const [loggedIn, setLoggedIn] = React.useState(false)

  useEffect(() => {
    handleAuth()
  },[])

  const handleAuth = async () => {
    const loggedIn_ = await isLoggedIn()
    setLoggedIn(loggedIn_)

    if (loggedIn_) {
      const user_: any = await getUser()
      setUser(user_)
    }
  }

  return (
    <div className="flex w-screen justify-around">
      <header className="z-30 top-4 bg-opacity-40 p-4 pt-[20px] pb-[20px] bg-[#1e283a] rounded-full md:w-4/6 w-5/6 fixed flex justify-between">
        <div className="gap-1 flex">
          <img className="w-12 h-12 rounded-full object-cover" src={require("../../images/new_new_logo_dark_bigger.png")} alt="" />
        </div>
        <div className="text-[orange] flex-none gap-4">
          <button onClick={props.scrollToReviews}> {getLabels().header.earnings}</button>
          <button onClick={props.scrollToQuestions}> {getLabels().header.faq}</button>
          {/*<button onClick={props.scrollToPlans}>Piani</button>*/}
        </div>
        {loggedIn ? (
          <div className="flex items-center">
            <button onClick={() => navigate("/" + user?.library_name)} className="w-10 h-10">
              <img className="object-cover w-full h-full rounded-full" src={user?.image_path} alt="" />
            </button>
          </div>
        ):(
          <div className="flex gap-4">
            <button
              onClick={() => navigate("/signin")} 
              className="transition-all font-semibold hover:bg-opacity-30 text-[orange] hover:bg-[orange] text-sm 
                        pl-3 pr-3 p-1 rounded-full border-2 border-[orange]"
            >
              {getLabels().header.signin}
            </button>
            <button
              onClick={() => navigate("/create")} 
              className="transition-all font-semibold hover:text-[orange] hover:bg-opacity-30 text-[white] border-2
                        border-[orange] text-sm pl-3 pr-3 p-1 rounded-full border border-[orange] bg-[orange]"
            >
              {getLabels().header.signup}
            </button>
          </div>
        )}
      </header>
    </div>
  );
}

export default Header;