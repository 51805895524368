import React, { useEffect } from "react";
import { FaCopy, FaFacebook, FaLinkedin, FaNewspaper, FaPaypal, FaStar, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";
import { IoClose, IoStarOutline } from "react-icons/io5";
import { MdClose, MdDone, MdEmail } from "react-icons/md";
import { RiEditFill, RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  FacebookIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { User } from "../../typos";
import axios from "axios";
import { BASE_URL, getCookie } from "../../utils/utils";
import { getLabels } from "../../utils/labels";
import StarsSelector from "../stars_selector";

interface CreateReviewModalProps {
  visible: boolean,
  onClose: (articleAdded?: boolean) => void
}

const dataSchema = {
  content: '',
  stars: 0
}

const CreateReviewModal: React.FC<CreateReviewModalProps> = ( props ) => {

  const navigate = useNavigate()
  const [created, setCreated] = React.useState(false)
  const [data, setData] = React.useState(dataSchema)

  if (!props.visible) {
    return null;
  }

  const create = async () => {
    await axios.post(BASE_URL + "/reviews/", data, {
      headers: {
        "Authorization": "Bearer " + getCookie("jwt-token"),
      }
    })
    .then((res) => {
      setCreated(true)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  return (
    <div className="top-0 left-0 z-40 w-screen bg-[black] bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div className="p-6">
        <div style={{ maxWidth: 314 }} className="p-6 rounded-lg bg-[white]">
          <div className="flex justify-between">
            <div></div>
            <button 
              onClick={() => {
                props.onClose()
                setCreated(false)
                setData(dataSchema)
              }}
              className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#e4e4e4] transition-all"
            >
              <IoClose size={24} color="gray"/>
            </button>
          </div>
          {created ? (
            <div className="font-[roboto]">
              <p className="text-lg">{getLabels().review_modal.created}</p>
              <div className="mt-4 flex justify-between">
                <div></div>
                <button
                  onClick={() => {
                    props.onClose()
                    setCreated(false)
                    setData(dataSchema)
                  }} 
                  className="transition-all hover:text-[orange] hover:bg-opacity-30 border 
                            border-[orange] pl-4 pr-4 text-[white] p-2 bg-[orange] rounded-full"
                  >
                    {getLabels().review_modal.exit}
                  </button>
              </div>
            </div>
          ):(
            <div className="font-[roboto]">
              <div>
                <h2 className="mb-1 text-lg">{getLabels().review_modal.stars}</h2>
                <StarsSelector onChange={(e) => setData({ ...data, stars: e })}/>
              </div>
              <div className="mt-3">
                <h2 className="text-lg">{getLabels().review_modal.content}</h2>
                <div className="mt-1 items-center bg-[white] flex pl-6 pr-2 pt-2 pb-2 rounded-lg border">
                  <textarea 
                    onChange={(e) => setData({ ...data, content: e.target.value })}
                    name="description"
                    placeholder={getLabels().review_modal.content_placeholder}
                    value={data.content} 
                    className="w-64 outline-none text-lg" 
                  />
                </div> 
              </div>
              <div className="mt-8 flex justify-between">
                <div></div>
                <button
                  disabled={data.content == ""} 
                  onClick={() => {
                    create()
                  }} 
                  className="transition-all hover:text-[orange] hover:bg-opacity-30 border 
                            border-[orange] pl-4 pr-4 text-[white] p-2 bg-[orange] rounded-full"
                  >
                    {getLabels().review_modal.create}
                  </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateReviewModal;