import React from "react"
import { FaStar } from "react-icons/fa"
import { IoStarOutline } from "react-icons/io5"

interface StarsSelectorProps {
  onChange: (stars: number) => void
}

const StarsSelector: React.FC<StarsSelectorProps> = ( props ) => {

  const [stars, setStars] = React.useState(0)

  const handleStars = (stars: number) => {
    setStars(stars)
    props.onChange(stars)
  }

  return (
    <div className="flex gap-2">
      {[...Array(5)].map((e, i) => (
        <button onClick={() => handleStars(i + 1)}>
          {i < stars ? <FaStar size={24} color="orange"/> : <IoStarOutline size={24} color="orange"/>}
        </button>
      ))}
    </div>
  )
}

export default StarsSelector