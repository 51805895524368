import { FaBook, FaBookOpen, FaBookmark, FaCalendar, FaCloud, FaLock, FaMobile, FaSave, FaStar } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Flag from "react-world-flags";
import Product from "../../components/product";
import './index.css'
import Order from "../order";
import { IoStatsChart } from "react-icons/io5";
import { BASE_URL, getCookie, isLoggedIn } from "../../utils/utils";
import { MdEuro } from "react-icons/md";
import { BarChart, LineChart } from "@mui/x-charts";
import { SpinnerCircular } from "spinners-react";
import axios from "axios";
import { User } from "../../typos";
import { Helmet } from "react-helmet";
import { getLabels } from "../../utils/labels";

const LibraryAnalytics = () => {

  const { libraryName } = useParams();
  const navigate = useNavigate()
  const [sellingsType, setSellingsType] = useState("day")
  const [earningsType, setEarningsType] = useState("day")
  const [loggedIn, setLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSellingsGraphLoading, setIsSellingsGraphLoading] = useState(true)
  const [isEarningsGraphLoading, setIsEarningsGraphLoading] = useState(true)

  const [userData, setUserData] = useState<User>({
    bio: "",
    city: "",
    name: "",
    surname: "",
    country_code: "",
    created_on: "", // Assuming this is in ISO 8601 format
    email: "",
    url: "",
    color: "",
    image_path: "",
    library_name: "",
    own: false,
    paypal_email: "",
    premium: false,
    user_id: 0
  })

  const [analyticsData, setAnalyticsData] = useState({
    products: 0,
    sold_products: 0,
    earnings: 0,
    created_on: 0
  })
  const [sellingsGraph, setSellingsGraph] = useState({
    x: [],
    y: []
  })
  const [earnigsGraph, setEarningsGraph] = useState({
    x: [],
    y: []
  })

  useEffect(() => {
    handleAuth()
  },[])

  const handleAuth = async () => {
    const loggedIn_ = await isLoggedIn()
    if (!loggedIn_) {
      navigate("/")
      return
    }
    getUser()
  }

  const getUser = async () => {
    await axios.get(BASE_URL + "/users/lib/" + libraryName, {
      headers: {
        "Authorization": "Bearer " + getCookie("jwt-token")
      }
    })
    .then((res) => {
      if (!res.data.param.own) {
        navigate("/")
      }
      setUserData(res.data.param)
      getAnalytics()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const getAnalytics = async (type?: string) => {
    setIsLoading(true)
    const usedType = type ? type : sellingsType
    await axios.get(BASE_URL + "/users/" + libraryName + "/analytics?filter=" + usedType)
    .then((res) => {
      setAnalyticsData(res.data.param)
      setIsLoading(false)
      getSellingsGraph()
      getEarningsGraph()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const getSellingsGraph = async (type?: string) => {
    setIsSellingsGraphLoading(true)
    const usedType = type ? type : sellingsType
    await axios.get(BASE_URL + "/users/" + libraryName + "/analytics?filter=" + usedType)
    .then((res) => {
      setSellingsGraph(res.data.param.graph)
      setIsSellingsGraphLoading(false)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const getEarningsGraph = async (type?: string) => {
    setIsEarningsGraphLoading(true)
    const usedType = type ? type : earningsType
    await axios.get(BASE_URL + "/users/" + libraryName + "/analytics?filter=" + usedType)
    .then((res) => {
      setEarningsGraph(res.data.param.graph1)
      setIsEarningsGraphLoading(false)
    })
    .catch((err) => {
      console.log(err)
    })
  }
  
  const generateGraphStyle = () => {
    if (userData.premium) {
      return ""
    }
    /*return "blur-md"*/
  }
  
  return (
    <>
      <Helmet>
        <title>Uteka - {userData.library_name} ({userData.name} {userData.surname})</title>
      </Helmet>
      <div className="bg-[#030816] pagepadding overflow-hidden w-screen h-screen">
        <div className="z-20 bz-30 flex justify-between w-screen h-screen relative w-full flex-block">
          <div className="block-none">
            <div className="w-64 h-64">
              <img style={{ borderColor: userData?.color }} className="border-4 w-full rounded-full h-full object-cover" src={userData.image_path} alt="" />
            </div>
            <div className="flex mt-8 items-center gap-3">
              <h1 className="font-semibold text-[white] text-4xl">{userData.name} {userData.surname}</h1>
              {userData.premium ? (
                <FaStar size={24} color="orange"/>
              ): null}
            </div>
            <h2 style={{ textDecorationColor: userData?.color }} className="underline text-[#95a3b9] text-lg mt-1">@{userData.library_name}</h2>
            <p style={{ maxWidth: 484 }} className="text-[#95a3b9] text-lg mt-2">{userData.bio}</p>
            <div className="flex justify-between">
              <a href={"https://www.google.com/maps/place/" + userData.city} target="__blank">
                <button style={{ borderColor: userData?.color }} className="gap-3 items-center rounded-full pl-4 flex border-2 pr-4 pt-1 pb-1 mt-8">
                  <div className="flex items-center gap-1">
                    <HiOutlineLocationMarker size={18} color={userData?.color} />
                    <p className="text-[white]">{userData.city}</p>
                  </div>
                  <div>
                    <Flag className="border rounded-sm w-5" code={userData.country_code} />
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div className="none-block">
            <div className="items-center flex">
              <div className="md:w-24 md:h-24 w-16 h-16">
                <img style={{ borderColor: userData?.color }} className="border-2 w-full rounded-full h-full object-cover" src={userData?.image_path} alt="" />
              </div>
              <div className="ml-4">
                <div className="flex items-center gap-3">
                  <h1 className="font-semibold text-[white] md:text-2xl text-xl">{userData?.name} {userData?.surname}</h1>
                  {userData?.premium ? (
                    <button>
                      <FaStar size={18} color="orange"/>
                    </button>
                  ): null}
                </div>
                <h2 style={{ textDecorationColor: userData?.color }} className="underline text-[#95a3b9] md:text-lg text:md">@{userData?.library_name}</h2>
              </div>
            </div>
            <div>
              <p style={{ maxWidth: 314 }} className="text-[#95a3b9] md:text-md text-sm md:mt-6 mt-2">{userData?.bio}</p>
            </div>
            <div className="pb-6 flex justify-between">
              <a href={"https://www.google.com/maps/place/" + userData.city} target="__blank">
                <button style={{ borderColor: userData?.color }} className="gap-3 items-center rounded-full pl-3 flex border-2 pr-3 pt-1 pb-1 mt-4">
                  <div className="flex items-center gap-1">
                    <HiOutlineLocationMarker size={14} color={userData?.color}/>
                    <p className="text-sm text-[white]">{userData?.city}</p>
                  </div>
                  <div>
                    <Flag className="border rounded-sm w-4" code={userData?.country_code} />
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div className="ml-14 w-3/4 ax flex-wrap p-4">
            <div>
              <div>
                <div className="flex gap-4">
                  <div className="bg-[#1e283a] border border-[#373D52] p-6 w-64 rounded-lg">
                    <div className="flex gap-4 justify-between">
                      <h2 className="font-regular text-[#95a3b9] text-md">{getLabels().library_analytics.available_products}</h2>
                      <div className="p-2 bg-[orange] bg-opacity-30 rounded-full">
                        <FaBook color="orange"/>
                      </div>
                    </div>
                    {isLoading ? (
                      <SpinnerCircular speed={314} color="orange" secondaryColor="#030816" thickness={250} size={28}/>
                    ) : (
                      <p className="font-semibold text-[white] text-2xl">{analyticsData.products}</p>                  
                    )}
                  </div>
                  <div className="bg-[#1e283a] border border-[#373D52] p-6 w-64 rounded-lg">
                    <div className="flex gap-4 justify-between">
                      <h2 className="font-regular text-[#95a3b9] text-md">{getLabels().library_analytics.total_sales}</h2>
                      <div className="p-2 bg-[orange] bg-opacity-30 rounded-full">
                        <FaBookOpen color="orange"/>
                      </div>
                    </div>
                    {isLoading ? (
                      <SpinnerCircular speed={314} color="orange" secondaryColor="#030816" thickness={250} size={28}/>
                    ) : (
                      <p className="font-semibold text-[white] text-2xl">{analyticsData.sold_products}</p>                  
                    )}
                  </div>
                </div>
                <div className="mt-4 flex gap-4">
                  <div className="bg-[#1e283a] border border-[#373D52] p-6 w-64 rounded-lg">
                    <div className="flex gap-4 justify-between">
                      <h2 className="font-regular text-[#95a3b9] text-md">{getLabels().library_analytics.total_earnings}</h2>
                      <div className="p-2 bg-[orange] bg-opacity-30 rounded-full">
                        <MdEuro color="orange"/>
                      </div>
                    </div>
                    {isLoading ? (
                      <SpinnerCircular speed={314} color="orange" secondaryColor="#030816" thickness={250} size={28}/>
                    ) : (
                      <p className="font-semibold text-[white] text-2xl">€{analyticsData.earnings},00</p>                  
                    )}
                  </div>
                  <div className="bg-[#1e283a] border border-[#373D52] p-6 w-64 rounded-lg">
                    <div className="flex gap-4 justify-between">
                      <h2 className="font-regular text-[#95a3b9] text-md">{getLabels().library_analytics.created_on}</h2>
                      <div className="p-2 bg-[orange] bg-opacity-30 rounded-full">
                        <FaCalendar color="orange"/>
                      </div>
                    </div>
                    {isLoading ? (
                      <SpinnerCircular speed={314} color="orange" secondaryColor="#030816" thickness={250} size={28}/>
                    ) : (
                      <p className="font-semibold text-[white] text-2xl">{analyticsData.created_on} <span className="text-sm">{getLabels().library_analytics.days}</span></p>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-[#1e283a] mt-4 h-80 border border-[#373D52] rounded-lg">
                <div className="flex justify-between p-4 pb-0">
                  <h2 className="font-semibold text-[#95a3b9] text-lg">{getLabels().library_analytics.sales}</h2>
                  <select
                    /*disabled={!userData.premium}*/ 
                    className="text-[white] outline-none bg-[#030816] border-[#373D52] rounded-md border p-2 pl-4 pr-4" 
                    onChange={(e) => {
                      setSellingsType(e.target.value)
                      getSellingsGraph(e.target.value)
                    }} 
                    value={sellingsType} 
                    name="" 
                    id=""
                  >
                    <option value="day">{getLabels().library_analytics.today}</option>
                    <option value="week">{getLabels().library_analytics.week}</option>
                    <option value="month">{getLabels().library_analytics.month}</option>
                    <option value="year">{getLabels().library_analytics.year}</option>
                  </select>
                </div>
                <div className="flex items-center justify-around">
                  {/*<div style={{ display: !userData.premium && !isSellingsGraphLoading && !isEarningsGraphLoading ? 'block' : 'none' }} className="z-20 absolute">
                    <div className="flex justify-around">
                      <FaLock size={45} color="orange"/>
                    </div>
                    <p className="mt-2 text-lg font-semibold font-[roboto]">Passa a premium per vedere il grafico</p>
                  </div>*/}
                  {isSellingsGraphLoading ? (
                    <SpinnerCircular className="mt-14" speed={314} color="orange" secondaryColor="#030816" thickness={154} size={54}/>
                  ):(
                    <div className={generateGraphStyle()}>
                      <LineChart
                        colors={["orange"]}
                        xAxis={[{ data: sellingsGraph.x, scaleType: 'band' }]}
                        series={[
                          {
                            data: sellingsGraph.y,
                            area: true,
                          },
                        ]}
                        width={500}
                        height={284}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-[#1e283a] ml-4 h-80 border border-[#373D52] rounded-lg">
              <div className="pb-0 flex justify-between pb-0 p-4">
                <h2 className="font-semibold text-[#95a3b9] text-lg">{getLabels().library_analytics.earnings}</h2>
                <select 
                  /*disabled={!userData.premium}*/ 
                  className="text-[white] outline-none bg-[#030816] border-[#373D52] rounded-md border p-2 pl-4 pr-4" 
                  onChange={(e) => {
                    setEarningsType(e.target.value)
                    getEarningsGraph(e.target.value)
                  }} 
                  value={earningsType} 
                  name="" 
                  id=""
                >
                  <option value="day">{getLabels().library_analytics.today}</option>
                  <option value="week">{getLabels().library_analytics.week}</option>
                  <option value="month">{getLabels().library_analytics.month}</option>
                  <option value="year">{getLabels().library_analytics.year}</option>
                </select>
              </div>
              <div className="flex items-center justify-around">
                {/*<div style={{ display: !isSellingsGraphLoading && !userData.premium && !isEarningsGraphLoading ? 'block' : 'none' }} className="z-20 absolute">
                  <div className="flex justify-around">
                    <FaLock size={45} color="orange"/>
                  </div>
                  <p className="mt-2 text-lg font-semibold">Passa a premium per vedere il grafico</p>
                  </div>*/}
                {isEarningsGraphLoading ? (
                  <SpinnerCircular className="mt-14" speed={314} color="orange" secondaryColor="#030816" thickness={154} size={54}/>
                ):(
                  <div className={generateGraphStyle()}>
                    <BarChart
                      xAxis={[
                        {
                          id: 'barCategories',
                          data: earnigsGraph.x,
                          scaleType: 'band',
                        },
                      ]}
                      colors={['orange']}
                      series={[
                        {
                          data: earnigsGraph.y,
                        },
                      ]}
                      width={500}
                      height={284}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-3/4 axi">
            <div className="flex items-center mt-24">
              <div className="flex justify-around">
                <FaMobile size={54} color="orange"/>
              </div>
              <p className="font-medium text-[white] ml-4 max-w-64 mt-4">{getLabels().library_analytics.no_mobile_available}</p>
            </div>
          </div>
        </div>
        <div className="z-30 bottom-8 fixed">
          <button 
            onClick={() => navigate("/" + userData.library_name)} 
            className="rounded-full text-[white] font-semibold pr-3 pt-1 pb-1 pl-3 bg-[orange]"
          >
            {getLabels().bottom_header.come_back}
          </button>
        </div>
      </div>
    </>
  );
}

export default LibraryAnalytics;