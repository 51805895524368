import axios from "axios";
import { useEffect, useState } from "react";
import { FaArrowCircleLeft, FaPaypal } from "react-icons/fa";
import { HiPhotograph } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL, setCookie } from "../../utils/utils";
import { getLabels } from "../../utils/labels";
import { MdDone, MdError } from "react-icons/md";
import { SpinnerCircular } from "spinners-react";

const CreatePassword = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [libraryName, setLibraryName] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false)
  const [curr, setCurr] = useState(1);
  const [data, setData] = useState({
    password: '',
    password_confirmation: '',
    recovery_token: ""
  });
  const navigate = useNavigate()
  const [error, setError] = useState("")

  const createPassword = async (e: any) => {
    setIsLoading(true)
    e.preventDefault()
    setError("")
    await axios.post(BASE_URL + "/users/create_password", data)
    .then((res) => {
      navigate("/signin")
    })
    .catch((err) => {
      console.log(err);
      setError(err.response.data.error.message)
    })
    setIsLoading(false)
  }

  const handleData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    setData({ ...data, recovery_token: searchParams.get("token") || "" })
  })

  const isPasswordValid = (password: string) => {
    return password.match("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$")
  }
  
  return (
    <>
      <div className="flex bg-[#030816] justify-around items-center w-screen h-screen">
        <div className="border border-[#373D52] bg-[#1e283a] z-20 p-8 rounded-lg">
          <h2 className="font-semibold text-[white] text-xl">{getLabels().create_password.title}</h2>
          <form onSubmit={(e) => createPassword(e)}>
            <p className="text-sm max-w-64 text-[#95a3b9]">{getLabels().create.description}</p>
            <div className="mt-6">
              <input 
                onChange={(e) => handleData(e)} 
                value={data.password} 
                name="password" 
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-8 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                placeholder={getLabels().create_password.password} 
                type="password" 
              />
              <input 
                onChange={(e) => handleData(e)}
                value={data.password_confirmation} 
                name="password_confirmation" 
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-8 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                placeholder={getLabels().create_password.password_confirmation}
                type="password" 
              />
            </div>
            <div className="mt-2">
              <p className="text-[red] text-xs">{error}</p>
            </div>
            <div className="mt-4">
              <div className="flex items-center">
                {data.password.length > 8 && data.password.length <= 16 ? (
                  <MdDone color="green" size={14}/>
                ):(
                  <MdError color="red" size={14}/>
                )}
                <p className="ml-2 text-[#95a3b9] text-xs">{getLabels().create.form.password_c1}</p>
              </div>
              <div className="flex items-center">
                {data.password.match(/\d+/) ? (
                  <MdDone color="green" size={14}/>
                ):(
                  <MdError color="red" size={14}/>
                )}
                <p className="ml-2 text-[#95a3b9] text-xs">{getLabels().create.form.password_c2}</p>
              </div>
              <div className="flex items-center">
                {data.password.match(/[A-Z]/) ? (
                  <MdDone color="green" size={14}/>
                ):(
                  <MdError color="red" size={14}/>
                )}
                <p className="ml-2 text-[#95a3b9] text-xs">{getLabels().create.form.password_c3}</p>
              </div>
            </div>
            <div className="mt-6 flex justify-between">
              <div></div>
              {isLoading ? (
                <button
                  disabled={true}
                  className="transition-all flex justify-around items-center bg-[orange] text-[orange] bg-opacity-30 border-2 
                            border-[orange] font-semibold pl-6 pr-6 p-2 rounded-lg"
                >
                  <SpinnerCircular speed={243} thickness={214} size={21} color="orange"/>
                </button>
              ):(
                <button
                  disabled={(data.password != data.password_confirmation) || !isPasswordValid(data.password)}
                  className="transition-all text-sm hover:text-[orange] hover:bg-opacity-30 border-2
                              border-[orange] font-semibold pl-6 pr-6 text-[white] p-2 bg-[orange] rounded-lg"
                  >
                    {getLabels().create_password.next}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CreatePassword;