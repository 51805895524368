import axios from "axios"
import { jwtDecode } from "jwt-decode"

//export const BASE_URL = "http://localhost:8000"
export const BASE_URL = "https://uteka.pythonanywhere.com"
export const MAINTENANCE = true
export const libraries = [
  {
    image: 'https://images.pexels.com/photos/4669626/pexels-photo-4669626.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Elena Kim",
    color: '#82BEA7',
    description: "Passionate photographer sharing secrets for stunning photos and videos",
    country_code: 'US',
    city: 'Clinton, NY',
    products: [
      {
        name: "Portrait Photography Masterclass",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 10
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Nadia Ali",
    color: '#2A83E4',
    description: "Simplifying tech with guides and articles!",
    country_code: 'PK',
    city: 'Islamabad',
    products: [
      {
        name: "Top 10 Tech Trends of 2024",
        type: {
          type_id: 1,
          name: 'article'
        },
        cost: 3
      },
      {
        name: "Smart Home Setup Guide",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 5
      }
    ]
  },
  {
    image: 'https://uteka.pythonanywhere.com/users/30471800/image',
    name: "Alberto Di Maio",
    color: '#A3CB38',
    description: "Hi, I'm Alberto, software developer and technology enthusiast",
    country_code: 'IT',
    city: 'Naples',
    products: [
      {
        name: "OOP Java vs Python, what's better?",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 8.99
      },
      {
        name: "Is AI stupid or smart?",
        type: {
          type_id: 2,
          name: 'article'
        },
        cost: 10
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/6851292/pexels-photo-6851292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Yuki Tanaka",
    color: '#FF5733',
    description: "Bringing Japanese flavors to your kitchen, Japanese food is so good",
    country_code: 'JP',
    city: 'Tokyo',
    products: [
      {
        name: "Sushi Making Workshop",
        type: {
          type_id: 2,
          name: 'recipe'
        },
        cost: 12
      },
      {
        name: "Guide to Japanese Knife Skills",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 7
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/3727457/pexels-photo-3727457.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Sophie Miller",
    color: '#FFC300',
    description: "Fueling creativity with design tips and resources",
    country_code: 'AU',
    city: 'Melbourne',
    products: [
      {
        name: "Adobe Photoshop Masterclass",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 15
      },
      {
        name: "Illustrator Techniques Ebook",
        type: {
          type_id: 1,
          name: 'ebook'
        },
        cost: 10
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/7991681/pexels-photo-7991681.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Marcus Wright",
    color: '#B866D1',
    description: "Transforming lives through fitness and nutrition!",
    country_code: 'US',
    city: 'Los Angeles',
    products: [
      {
        name: "30-Day Fitness Challenge",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 20
      },
      {
        name: "Healthy Eating Cookbook",
        type: {
          type_id: 2,
          name: 'recipe'
        },
        cost: 12
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/2660262/pexels-photo-2660262.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Sofia Hernandez",
    color: '#FF5733',
    description: "Guiding adventures to uncover hidden gems!",
    country_code: 'MX',
    city: 'Cancun',
    products: [
      {
        name: "Ultimate Backpacking Checklist",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 8
      },
      {
        name: "Hidden Gems of South America Ebook",
        type: {
          type_id: 1,
          name: 'ebook'
        },
        cost: 6
      }
    ]
  },
  {
    image: 'https://via.placeholder.com/150',
    name: "Mira Patel",
    color: '#A3CB38',
    description: "Unlocking DIY genius for stylish home decor, I'm good at this",
    country_code: 'IN',
    city: 'Mumbai',
    products: [
      {
        name: "DIY Home Decor Ideas",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 10
      },
      {
        name: "Crafting with Recycled Materials Ebook",
        type: {
          type_id: 1,
          name: 'ebook'
        },
        cost: 7
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/4649391/pexels-photo-4649391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Anton Petrov",
    color: '#82BEA7',
    description: "Exploring the cosmos and unraveling its mysteries!",
    country_code: 'RU',
    city: 'Moscow',
    products: [
      {
        name: "Astrophysics Explained Series",
        type: {
          type_id: 1,
          name: 'article'
        },
        cost: 5
      },
      {
        name: "Chemistry Basics Guide",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 3
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/10675693/pexels-photo-10675693.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Liam O'Connor",
    color: '#2A83E4',
    description: "Igniting musical passions with expert lessons!",
    country_code: 'IE',
    city: 'Dublin',
    products: [
      {
        name: "Guitar Basics Course",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 15
      },
      {
        name: "Piano Techniques Ebook",
        type: {
          type_id: 1,
          name: 'ebook'
        },
        cost: 12
      }
    ]
  },
  {
    image: 'https://images.pexels.com/photos/5688727/pexels-photo-5688727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: "Emilia Silva",
    color: '#FF5733',
    description: "Unveiling fashion trends and styling secrets, start dressing better",
    country_code: 'BR',
    city: 'Sao Paulo',
    products: [
      {
        name: "Fall/Winter Fashion Lookbook",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 8
      },
      {
        name: "DIY Clothing Alterations",
        type: {
          type_id: 1,
          name: 'guide'
        },
        cost: 5
      }
    ]
  }
]

export const isLoggedIn = async () => {
  
  const cookie = getCookie("jwt-token")
  let isLoggedIn_ = true

  if (!cookie) {
    isLoggedIn_ = false;
  }

  await axios.post(BASE_URL + "/users/sync", {}, { 
    headers: { 
      Authorization: "Bearer " + cookie 
    }
  })
  .then(res => {
    
  })
  .catch(err => {
    isLoggedIn_ = false;
  })

  return isLoggedIn_
  
}

export const getUser = async () => {
  const cookie: string | undefined = getCookie("jwt-token")
  let user = {}
  if (cookie !== undefined) {
    const userId = jwtDecode<any>(cookie).sub.user_id
    await axios.get(BASE_URL + "/users/" + userId, { headers: { Authorization: 'Bearer ' + getCookie("jwt-token") } })
    .then(res => {
      user = res.data.param
    })
    .catch(err => {
      
    })
  }
  return user
}

export const setCookie = (cookie: string, cValue: string, expDays: number) => {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookie + "=" + cValue + "; " + expires + "; path=/";
}

export const getCookie = (cookie: string) => {
  const name = cookie + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded .split('; ');
  let res;
  cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}

export const eraseCookie = (cookie: string) => {   
  document.cookie = cookie+'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';  
}