import React, { useEffect, useState } from "react";
import { FaCopy, FaFacebook, FaLinkedin, FaNewspaper, FaPaypal, FaStar, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdClose, MdDone, MdEmail } from "react-icons/md";
import { RiEditFill, RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import "./index.css"
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  FacebookIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { User } from "../../typos";
import axios from "axios";
import { BASE_URL, getCookie } from "../../utils/utils";
import { getLabels } from "../../utils/labels";
import { SpinnerCircular } from "spinners-react";

interface CreateProductModalProps {
  visible: boolean,
  onClose: (articleAdded?: boolean) => void
}

const dataSchema = {
  title: '',
  description: '',
  bio: '',
  cost: undefined,
  type_id: 0,
  file: ''
}

const CreateProductModal: React.FC<CreateProductModalProps> = ( props ) => {

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = React.useState(dataSchema)
  const [fileName, setFileName] = React.useState("")
  const [error, setError] = React.useState("")
  const [types, setTypes] = React.useState<any>([])

  const getTypes = () => {
    axios.get(BASE_URL + "/types")
    .then((res) => {
      setTypes(res.data.param)
      setData({ ...data, type_id: res.data.param[0].type_id })
    })
    .catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    getTypes()
  },[])

  if (!props.visible) {
    return null;
  }

  const handleData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {   
    const file = e.target.files && e.target.files[0];
    const extension = file?.name.split(".")[1]

    if (extension != "pdf") {
      setError(getLabels().create_modal.extension_error)
      return
    }

    if (file) {
      setFileName(file.name)
      const reader = new FileReader();
      reader.onload = () => {
        setData({
          ...data,
          file: reader.result?.toString().split(",")[1] || ""
        });
        console.log(reader.result?.toString())
      };
      reader.readAsDataURL(file);
    }
  }

  const create = async () => {
    setIsLoading(true)
    await axios.post(BASE_URL + "/products/", data, {
      headers: {
        "Authorization": "Bearer " + getCookie("jwt-token"),
      }
    })
    .then((res) => {
      props.onClose(true)
      setData(dataSchema)
    })
    .catch((err) => {
      console.log(err);
    })
    setIsLoading(false)
  }

  return (
    <div className="top-0 left-0 z-40 w-screen backdrop-blur-sm bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div className="p-6">
        <div style={{ maxWidth: 314 }} className="p-6 rounded-lg border border-[#373D52] bg-[#1e283a]">
          <div className="flex justify-between">
            <div></div>
            <button 
              onClick={() => {
                props.onClose()
                setData(dataSchema)
              }}
              className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#373D52] transition-all"
            >
              <IoClose size={24} color="gray"/>
            </button>
          </div>
          <div className="">
            <h2 className="text-[white] text-xl font-semibold"> {getLabels().create_modal.title}</h2>
            <p className="text-sm mt-1 text-[#95a3b9]"> {getLabels().create_modal.label}</p>
            <div className="flex mt-4 justify-around">
              <div>
                {data.file != "" ? (
                  <label>
                    <input className="hidden" onChange={(e) => handleFile(e)} accept=".pdf" type="file" />
                    <div className="p-1 textl-lg pl-2 pr-2 cursor-pointer items-center text-[white] rounded-lg border bg-[orange] border-[orange] flex gap-1">
                      <MdDone/>
                      <p>{fileName.length > 18 ? fileName.substring(0,18) + "..." : fileName}</p>
                    </div>
                  </label>
                ):(
                  <label>
                    <input className="hidden" onChange={(e) => handleFile(e)} accept=".pdf" type="file" />
                    <div className="p-1 pl-2 text-lg pr-2 cursor-pointer items-center bg-transparent text-[orange] rounded-lg border-2 border-[orange] flex gap-1">
                      <FaNewspaper/>
                      <p className="text-sm">{getLabels().create_modal.pick}</p>
                    </div>
                  </label>
                )}
                <p className="text-[red] max-w-32" >{error}</p>
              </div>
            </div>
            <input 
              onChange={(e) => handleData(e)} 
              name="title" 
              placeholder= {getLabels().create_modal.name} 
              value={data.title} 
              className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
            />
            <input 
              onChange={(e) => handleData(e)} 
              name="description" 
              placeholder= {getLabels().create_modal.description}
              value={data.description}
              className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
              type="text" 
            />
            <select onChange={(e) => handleData(e)} name="type_id" value={data.type_id} className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-4 pt-2 pb-2 rounded-lg border border-[#373D52] text-md">
              {types.map((e: any) => (
                <option value={e.type_id}>{e.name}</option>
              ))}
            </select>
            <label className="flex items-center">
              <span className="absolute pl-4 pt-4 text-md text-[orange]">
                <span data-domain>$</span>
              </span>
              <input
                onChange={(e) => handleData(e)} 
                name="cost"
                step={0.99}
                placeholder={"$12.99"}
                value={data.cost}
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-8 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                type="number" 
              />
            </label>
            <div className="mt-4 flex justify-between">
              <div></div>
              {isLoading ? (
                <button 
                  disabled={true}
                  className="transition-all bg-[orange] text-[orange] bg-opacity-30 border-2 
                            border-[orange] font-semibold pl-4 pr-4 p-2 rounded-full"
                >
                  <SpinnerCircular speed={243} thickness={214} size={24} color="orange"/>
                </button>
              ):(
                <button 
                  disabled={
                    data.title == "" || 
                    data.description == "" || 
                    data.type_id == 0 ||
                    data.file == ""
                  } 
                  onClick={() => {
                    create()
                  }}
                  className="transition-all hover:text-[orange] hover:bg-opacity-30 border-2 
                            border-[orange] font-semibold pl-4 pr-4 text-[white] p-2 bg-[orange] rounded-full"
                >
                  {getLabels().create_modal.create}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProductModal;