import React, { useEffect } from "react";
import { FaCopy, FaFacebook, FaLinkedin, FaNewspaper, FaPaypal, FaStar, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { HiLink, HiXCircle } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdClose, MdDone, MdEmail } from "react-icons/md";
import { RiEditFill, RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  FacebookIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { User } from "../../typos";
import axios from "axios";
import { BASE_URL, getCookie } from "../../utils/utils";
import { getLabels } from "../../utils/labels";
import Confetti from "react-confetti/dist/types/Confetti";
import ReactConfetti from "react-confetti";

interface LiveUrlModalProps {
  visible: boolean,
  url: string,
  onClose: (articleAdded?: boolean) => void
}

const LiveUrlModal: React.FC<LiveUrlModalProps> = ( props ) => {

  const navigate = useNavigate()

  if (!props.visible) {
    return null;
  }

  return (
    <>
      <ReactConfetti/>
      <div className="top-0 left-0 z-40 w-screen backdrop-blur-sm bg-opacity-40 flex justify-around items-center h-screen absolute">
        <div className="p-6">
          <div style={{ maxWidth: 314 }} className="p-6 rounded-lg border border-[#373D52] bg-[#1e283a]">
            <div className="flex justify-between">
              <div></div>
              <button 
                onClick={() => props.onClose()}
                className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#373D52] transition-all"
              >
                <IoClose size={24} color="gray"/>
              </button>
            </div>
            <div className="">
              <h2 className="text-[white] text-xl font-semibold"> {getLabels().live_url.title}</h2>
              <p className="text-sm mt-1 text-[#95a3b9]"> {getLabels().live_url.label}</p>
              <div className="mt-6 flex bg-[#030816] bg-opacity-20 justify-between items-center gap-2 rounded-xl pl-4 border p-2 border-[#373D52]">
                <p className="text-md font-semibold text-[#95a3b9]">{props.url.split("//")[1].length > 18 ? props.url.split("//")[1].substring(0,17) + "..." : props.url.split("//")[1]}</p>
                <button onClick={() => props.onClose()} className="transition-all hover:text-[orange] hover:bg-opacity-30 border-2 border-[orange] 
                                   pl-4 font-semibold pr-4 text-[white] p-1 bg-[orange] rounded-full"
                >
                  Live
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveUrlModal;