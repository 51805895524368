import React, { useEffect, useState } from "react";
import { FaCopy, FaFacebook, FaLinkedin, FaPaypal, FaStar, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdClose, MdEmail } from "react-icons/md";
import { RiEditFill, RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  LinkedinIcon,
  FacebookIcon,
  XIcon,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon
} from "react-share";
import { User } from "../../typos";
import axios from "axios";
import { BASE_URL, getCookie } from "../../utils/utils";
import { getLabels } from "../../utils/labels";
import { SpinnerCircular } from "spinners-react";

interface AccountModalProps {
  visible: boolean,
  user: User,
  onChange: (user: User) => void,
  onClose: () => void
}

const AccountModal: React.FC<AccountModalProps> = ( props ) => {
  
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const themes = ["orange", "#82BEA7", "#2A83E4", "#FF5733", "#A3CB38", "#B866D1", "#d4d651", "#3AC927"]
  const [userPassword, setUserPassword] = React.useState<string>("")
  const [newUser, setNewUser] = React.useState<User>(props.user)
  const [searchingPlace, setSearchingPlace] = React.useState(props.user.city + ", " + props.user.country_code);
  const [places, setPlaces] = React.useState([])
  const [isPlacesVisible, setIsPlacesVisible] = React.useState(false)
  const [error, setError] = React.useState("")
  const [isPlacesLoading, setIsPlacesLoading] = React.useState(false)
  const [imageChanged, setImageChanged] = React.useState(false)
  
  
  const searchPlaces = async () => {
    await axios.get("https://nominatim.openstreetmap.org/search?q=" + searchingPlace + "&format=json&addressdetails=addressdetails")
    .then((res) => {
      setPlaces(res.data.filter((e: any) => e.addresstype == 'city'))
      setIsPlacesLoading(false)
    })
    .catch((err) => {
      console.log(err);
    })
  }
  
  useEffect(() => {
    setIsPlacesLoading(true)
    const delayDebounceFn = setTimeout(() => {
     searchPlaces()
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchingPlace])

  if (!props.visible) {
    return null;
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    const extension = file?.name.split(".")[1]

    if (extension != "png") {
      setError(getLabels().account_modal.extension_error)
      return
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewUser({
          ...newUser,
          image_path: reader.result?.toString() || ""
        });
      };
      reader.readAsDataURL(file);
      setImageChanged(true)
    }
  }

  const isEmailValid = (email: string) => {
    return email.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)
  }

  const isPasswordValid = (password: string) => {
    return password.match("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$")
  }

  const handleData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({...newUser, [e.target.name]: e.target.value})
  }

  const update = () => {
    setIsLoading(true)
    axios.put(BASE_URL + "/users/", { ...newUser, password: userPassword}, {
      headers: {
        "Authorization": "Bearer " + getCookie("jwt-token"),
      }
    })
    .then((res) => {
      if (imageChanged) {
        navigate(0)
      } else {
        props.onChange(res.data.param)
        setNewUser(res.data.param)
        props.onClose()
      }
    })
    .catch((err) => {
      console.log(err);
    })
    setIsLoading(false)
  }

  return (
    <div className="top-0 left-0 z-40 w-screen backdrop-blur-sm bg-opacity-40 flex justify-around items-center h-screen absolute">
      <div className="p-6">
        <div style={{ maxWidth: 314 }} className="p-6 rounded-lg border border-[#373D52] bg-[#1e283a]">
          <div className="flex justify-between">
            <div></div>
            <button
              onClick={() => {
                props.onClose()
                setNewUser(props.user)
              }}
              className="rounded-full p-1 hover:bg-opacity-50 hover:bg-[#373D52] transition-all"
            >
              <IoClose size={24} color="gray"/>
            </button>
          </div>
          <div>
            <div className="flex justify-around">
              <div>
                <label className="cursor-pointer w-32 h-32 flex justify-around items-center rounded-full">
                  <input className="hidden" onChange={(e) => handleFile(e)} accept=".png" type="file"/>
                  <img className="w-full h-full object-cover rounded-full" src={newUser.image_path} alt="" />
                </label>
                <p className="text-[red] max-w-32" >{error}</p>
              </div>
            </div>
            <input 
              placeholder={getLabels().account_modal.name} 
              onChange={(e) => handleData(e)} 
              name="name" 
              value={newUser.name} 
              className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
              type="text" 
            />
            <input 
              placeholder={getLabels().account_modal.surname} 
              onChange={(e) => handleData(e)} 
              name="surname" 
              value={newUser.surname} 
              className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
              type="text" 
            />
            <input 
              placeholder={getLabels().account_modal.description} 
              onChange={(e) => handleData(e)} 
              name="bio" 
              value={newUser.bio} 
              className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
            />
            <div>
              <input value={searchingPlace} onChange={(e) => {
                  setSearchingPlace(e.target.value)
                  setIsPlacesVisible(true)
                }} 
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                placeholder={getLabels().account_modal.city} 
                type="text" 
              />
              <div className="border border-[#373D52] absolute z-20 bg-[#1e283a] rounded-b-lg">
                {isPlacesLoading ? (
                  <div className="p-4">
                    <p className="text-[#95a3b9] text-md">Caricamento...</p>
                  </div>
                ):(
                  isPlacesVisible ? (
                    places.map((e: any) => (
                      <div onClick={() => {
                        setIsPlacesVisible(false)
                        setNewUser({ ...newUser, country_code: e.address.country_code, city: e.name })
                        setSearchingPlace(e.name + ", " + e.address.country_code.toUpperCase())
                      }} className="p-2 hover:bg-[#373D52] hover:bg-opacity-30 cursor-pointer">
                        <p className="text-[#95a3b9]">{e.name}, {e.address.country}</p>
                      </div>
                    ))
                  ) : null
                )}
              </div>
            </div>
            <label className="flex items-center">
              <span className="absolute pl-4 pt-4 text-md text-[#95a3b9]">
                <span data-domain>
                  <FaPaypal/>
                </span>
              </span>
              <input
                onChange={(e) => handleData(e)}
                name="paypal_email" 
                placeholder={getLabels().account_modal.paypal_email}
                value={newUser.paypal_email} 
                className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-10 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
                type="text" 
              />
            </label>
            <input 
              onChange={(e) => setUserPassword(e.target.value)} 
              placeholder="********" 
              name="password"
              value={userPassword} 
              className="w-64 mt-4 focus:outline focus:outline-[orange] text-[#95a3b9] bg-[#030816] bg-opacity-20 flex pl-6 pr-2 pt-2 pb-2 rounded-lg border border-[#373D52] text-md" 
              type="password"
            />
            <div>
              <p className="text-center text-sm mt-4 text-[#95a3b9] font-medium">Color themes</p>
              <div className="flex mt-2 gap-2">
                {themes.map(theme => (
                  theme == newUser.color ? (
                    <button style={{ backgroundColor: theme }} className="w-6 h-6 rounded-full border-2 outline outline-[2px] outline-offset-2 outline-[orange]"></button>
                  ):(
                    <button onClick={() => setNewUser({ ...newUser, color: theme })} style={{ backgroundColor: theme }} className="w-6 h-6 rounded-full opacity-50"></button >
                  )
                ))}
              </div>
            </div>
            <div className="mt-8 flex justify-between">
              <div></div>
              {isLoading ? (
                <button 
                  disabled={true}
                  className="transition-all bg-[orange] text-[orange] bg-opacity-30 border-2 
                            border-[orange] font-semibold pl-4 pr-4 p-2 rounded-full"
                >
                  <SpinnerCircular speed={243} thickness={214} size={24} color="orange"/>
                </button>
              ):(
                <button
                  disabled={
                    newUser.name == "" || 
                    newUser.surname == "" || 
                    newUser.bio == "" ||
                    newUser.city == "" || 
                    newUser.country_code == "" || 
                    !isEmailValid(newUser.paypal_email) ||
                    (!isPasswordValid(userPassword) && userPassword != "")
                  }
                  onClick={() => {
                    update()
                  }}
                  className="transition-all hover:text-[orange] hover:bg-opacity-30 border-2 border-[orange] pl-4 pr-4 font-semibold
                            text-[white] p-2 bg-[orange] rounded-full"
                  >
                    {getLabels().account_modal.change}
                  </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountModal;