import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homepage';
import Create from './pages/create';
import Library from './pages/library';
import Order from './pages/order';
import LibraryAnalytics from './pages/library_analytics';
import Signin from './pages/signin';
import Recover from './pages/recover';
import CreatePassword from './pages/create_password';
import { MAINTENANCE } from './utils/utils';
import NotFound from './pages/not_found';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/create_password' element={<CreatePassword/>}/>
        <Route path='/signin' element={<Signin/>}/>
        <Route path='/recover' element={<Recover/>}/>
        <Route path='/:libraryName' element={<Library/>}/>
        <Route path='/:libraryName/analytics' element={<LibraryAnalytics/>}/>
        <Route path='/create' element={<Create/>}/>
        <Route path='/*' element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
